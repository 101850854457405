import React, { FC, useEffect, useState } from "react";
import "./EstimatesItemDetail.css";
import CotegoryItemList from "../../Catalog/Components/ItemList";
// import Natural from "../../../assets/Categories/Natural Stone.jpg";
// import Quartz from "../../../assets/Categories/Quartz.jpg";
import BlackLeftArrow from "../../../assets/HomePage/Black Left Arrow.svg";
import { EstimatesItemDetailModel } from "../../../services/Models/Estimator.models";
import { ItemCard } from "../../Catalog/Components/ItemCard";
import { useNavigate, useParams } from "react-router-dom";
import AuthService from "../../../auth/auth.service";
import { EstimateService } from "../../../services/API/estimate.service";
import { toast } from "react-toastify";
import { BusyIndicator } from "../../HelperComps/BusyIndicator";

type EstimatesItemDetailProps = {};

const EstimatesItemDetail: FC<EstimatesItemDetailProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { estimationId } = useParams();
  const defaultRowData = {} as EstimatesItemDetailModel;
  const [rowData, setRowData] =
    useState<EstimatesItemDetailModel>(defaultRowData);

  const navigate = useNavigate();
  const estimateService = EstimateService.Instance;

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      const email = AuthService.getCurrentUser()?.email;
      // if (!email) {
      //   AuthService.Instance.logout();
      //   setTimeout(() => {
      //     toast.error("Something went wrong, please login again");
      //     setIsLoading(false);
      //     navigate("/auth/login");
      //   }, 1000);
      //   return;
      // }
      await estimateService
        .getEstimateDetail(email ?? "", estimationId ?? "")
        .then((res) => {
          setIsLoading(false);
          setRowData(res);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    };
    loadData();
  }, [estimationId]);

  const handleItemClick = (productId: number) => {
    navigate(`/category/item/${productId}`);
  };

  return (
    <section className="estimate-deail-root">
      <BusyIndicator loading={isLoading} />
      <header>
        <button
          className="back-arrow-btn"
          onClick={() => {
            navigate(-1);
          }}
        >
          <img src={BlackLeftArrow} alt="" />
        </button>
        <h2>Your Estimates</h2>
        <p>
          This is the list of all the estimates you have requested till now.
          Your estimates are usually updated in about 5 business days.
        </p>
      </header>
      <div className="estimate-deail-body">
        <div className="estimate-deail-body-header">
          <div>
            <h3>Kitchen Flooring Estimate</h3>
            <span>ID : 2144321</span>
          </div>
          <span
            style={{
              background:
                rowData?.estimationStatus === "Complete"
                  ? "#009D3F"
                  : "#ED4337",
            }}
          >
            {rowData?.estimationStatus === "Complete" ? "Completed" : "Pending"}
          </span>
        </div>
        <hr className="solid"></hr>
        <label htmlFor="quantity">Quantity Requested</label>
        <div id="quantity">
          3200
          <div>
            <span>
              m <sup>2</sup>
            </span>
            <span>/</span>
            <span className="inactive">
              f <sup>2</sup>
            </span>
          </div>
        </div>
        <section id="products-list">
          {rowData?.products?.length > 0 ? (
            window.innerWidth > 480 ? (
              <CotegoryItemList
                categoryTitle="Products Requested"
                itemList={rowData?.products}
                titleSize="16px"
                isTwoitemsPerRow={true}
              />
            ) : (
              <div className="products-list-mobile">
                <h3>Products Requested</h3>
                {rowData?.products?.map((item) => (
                  <ItemCard
                    key={item.productId}
                    item={item}
                    handleClick={handleItemClick}
                    isTwoitemsPerRow={true}
                  />
                ))}
              </div>
            )
          ) : (
            <>
              <h3 style={{ fontWeight: 500 }}>Products Requested</h3>
              <span style={{ color: "gray" }}> No items found</span>
            </>
          )}
        </section>
      </div>
    </section>
  );
};

export default EstimatesItemDetail;
