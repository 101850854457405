import { get, isEmpty, isUndefined } from "lodash";
import { COMMON_HEADERS } from "./common.constants";
import { APIConfirmMessage } from "./api-response.model";
import AuthService from "../../auth/auth.service";
import authHeader from "../../auth/auth.header";

export class CommonService {
  public static RequestInfo(httpMethod: string, payload?: any) {
    const requestInfo = {
      method: httpMethod,
      "Access-Control-Allow-Origin": "*",
      headers: {
        ...COMMON_HEADERS,
        ...authHeader(), // Include authorization header
      },
    } as RequestInit;

    if (!isUndefined(payload)) {
      requestInfo.body = JSON.stringify(payload);
    }

    return requestInfo;
  }

  public static HttpResponse(response: Response, returnMessage = false) {
    const contentLength = response.headers?.get("Content-Length");
    if (contentLength === "0") {
      return CommonService.readErrorMessage(response);
    }
    return response.json().then((resBody) => {
      const message = CommonService.readErrorMessage(response, resBody);
      return returnMessage ? message : resBody;
    });
  }

  public static HttpFileResponse(response: Response, returnMessage = false) {
    const contentLength = response.headers?.get("Content-Length");
    if (contentLength === "0") {
      return CommonService.readErrorMessage(response);
    }
    const contentType = response.headers?.get("Content-Type")?.split(";")[0];
    if (contentType === "application/json" || contentType === "text/plain") {
      return response.json().then((resBody) => {
        const message = CommonService.readErrorMessage(response, resBody);
        return returnMessage ? message : resBody;
      });
    }
    return response.blob();
  }

  public static readErrorMessage(response: Response, resBody?: any): string {
    const userMessage =
      CommonService.readConfirmMessage(
        resBody?.confirmMessage ?? resBody,
        response.status
      ) ??
      resBody?.errorMsg ??
      resBody ??
      response.statusText;
    if (response.status === 200) {
      return userMessage;
    }

    let errorResponse = "The application has encountered an error.";
    switch (response.status) {
      case 412: {
        errorResponse = `Precondition failed ${userMessage.message}`;
        window.location.href = window.location.origin;
        break;
      }
      case 401: {
        errorResponse = `Something went wrong, please login again: ${userMessage.message}`;
        // AuthService.Instance.logout();
        // window.location.href = window.location.origin + "/auth/login";
        break;
      }
      case 301:
      case 302: {
        errorResponse = `Session timed out, please login again. ${userMessage.message}`;
        window.location.href = window.location.origin + "/auth/login";
        break;
      }
      default: {
        errorResponse = `${userMessage.message}`;
        break;
      }
    }
    throw new Error(errorResponse);
  }

  private static readConfirmMessage(
    confirmMessage: APIConfirmMessage,
    status: number
  ): string | undefined {
    if (Array.isArray(confirmMessage?.message)) {
      const entityError: string[] =
        get(confirmMessage, "userMessage[0].loc") ?? [];
      const entityMsg = get(confirmMessage, "userMessage[0].msg");
      return `${entityError?.toString()}, ${entityMsg}`;
    }
    let userMessage: string | undefined =
      get(confirmMessage, "userMessage") ??
      get(confirmMessage, "UserMessage.Message") ??
      get(confirmMessage, "error");
    const errorId =
      get(confirmMessage, "confirmMessageID") ?? get(confirmMessage, "TaaSID");
    if (!isEmpty(errorId) && status !== 200) {
      userMessage = `${userMessage}. ErrorId: ${errorId}`;
    }
    return userMessage;
  }
}
