import React, { FC, useEffect, useState } from "react";
import "./EstimatesList.css";
import EstimatesListItem from "./EstimatesListItem";
import { EstimatesListModel } from "../../../services/Models/Estimator.models";
// import NeroMarquina from "../../../assets/Categories/Quartz/Nero Marquina - Full Slab.jpg";
// import Natural from "../../../assets/Categories/Natural Stone.jpg";
// import Quartz from "../../../assets/Categories/Quartz.jpg";
// import Ceramic from "../../../assets/Categories/Ceramic Tiles.jpg";
import Pagination from "../../HelperComps/Pagination";
import { EstimateService } from "../../../services/API/estimate.service";
import AuthService from "../../../auth/auth.service";
import { BusyIndicator } from "../../HelperComps/BusyIndicator";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

type EstimatesListProps = {};

const Natural = "";
const Quartz = "";
const NeroMarquina = "";
const Ceramic = "";

const EstimatesList: FC<EstimatesListProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const defaultRowData: EstimatesListModel = {
    data: [
      {
        estimateId: 2144321,
        dateCreated: "19-01-2024",
        name: "Nero Marquina Estimate",
        estimationStatus: "Pending",
        imgUrls: [NeroMarquina],
      },
      {
        estimateId: 2143902,
        dateCreated: "02-01-2024",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Pending",
        imgUrls: [Ceramic, Natural, Quartz, Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2142018,
        dateCreated: "27-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Complete",
        imgUrls: [Quartz, Natural],
      },
      {
        estimateId: 2143813,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Pending",
        imgUrls: [Ceramic, Natural, Quartz, Natural],
      },
      {
        estimateId: 2144321,
        dateCreated: "14-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Complete",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2144321,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Complete",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2144321,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Complete",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2144321,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Pending",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2144321,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Pending",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2144321,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Complete",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        estimateId: 2144321,
        dateCreated: "21-12-2023",
        name: "Kitchen Flooring Estimate",
        estimationStatus: "Pending",
        imgUrls: [Natural, Quartz, Ceramic],
      },
    ],
    pageNumber: 1,
    pageSize: 5,
    total: 11,
  };
  // const defaultRowData: EstimatesListModel = {} as EstimatesListModel;
  const [rowData, setRowData] = useState<EstimatesListModel>(defaultRowData);
  const indexOfLastItem = rowData.pageNumber * rowData.pageSize;
  const indexOfFirstItem = indexOfLastItem - rowData.pageSize;
  const currentItems = rowData.data
    ? rowData.data.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const estimateService = EstimateService.Instance;

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (!currentUser?.["MAT-TOKEN"]) {
        AuthService.Instance.logout();
        setTimeout(() => {
          toast.error("Something went wrong, please login again");
          setIsLoading(false);
          navigate("/auth/login");
        }, 1000);
        return;
      }
      await estimateService
        .getEstimates(currentUser?.email ?? "")
        .then((res) => {
          setIsLoading(false);
          setRowData(res);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    };
    loadData();
  }, []);

  return (
    <section className="estimator-list-root">
      <BusyIndicator loading={isLoading} />
      <header className="estimator-list-header">
        <h2>Your Estimates</h2>
        <p>
          This is the list of all the estimates you have requested till now.
          Your estimates are usually updated in about 5 business days.{" "}
        </p>
      </header>
      <div className="filters-wraper">
        <input type="search" placeholder="Search..." />
        <div>
          <div className="select-wraper">
            <select name="filter" id="filter">
              <option value="all">All</option>
              <option value="Filter1">Filter1</option>
              <option value="Filte2">Filte2</option>
              <option value="None">None</option>
            </select>
          </div>
          <div className="select-wraper">
            <select name="sort" id="sort">
              <option value="latest">Latest</option>
              <option value="latest">Oldest</option>
              <option value="latest">Size</option>
              <option value="latest">Date</option>
            </select>
          </div>
        </div>
      </div>
      <div className="estimator-list-items-wraper">
        {currentItems?.map((item, index) => (
          <EstimatesListItem
            key={item.estimateId}
            item={item}
            sNo={rowData.pageSize * (rowData.pageNumber - 1) + index + 1}
          />
        ))}
      </div>
      <Pagination rowData={rowData} setRowData={setRowData} />
    </section>
  );
};

export default EstimatesList;
