import { isEmpty, isNull } from "lodash";
import { AlertStatus, MessageAlertModel } from "../Models/Common.models";
import dayjs from "dayjs";

export class CommonUtils {

    public static setItem<T>(key: string, value: T) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public static removeItem(key: string) {
        localStorage.removeItem(key);
    }

    public static getItem<T>(key: string): T | undefined {
        const storedValue = localStorage.getItem(key);
        if (!isNull(storedValue)) {
            return JSON.parse(storedValue) as T;
        }
        return undefined;
    }

    public static setErrorMessage(err: any) {
        let errorMessage = err.message ?? err.errorMsg ?? err;
        return CommonUtils.setAlertMessage(errorMessage, "error");
    }

    public static setAlertMessage(alertMsg: string, alertStatus: AlertStatus) {
        if (!isEmpty(alertMsg)) {
            const messageAlert: MessageAlertModel = {
                message: alertMsg,
                status: alertStatus
            }
            return messageAlert;
        }
    }

    public static FormatDate(date: string){
        return dayjs(date).format('DD MMMM, YYYY')
    }
}