import React, { FC, useState } from "react";
import "../Estimates/EstimatesItemDetail.css";
import "./OrdersItemDetial.css";
// import Natural from "../../../assets/Categories/Natural Stone.jpg";
// import Quartz from "../../../assets/Categories/Quartz.jpg";
import WhiteRightArrow from "../../../assets/Profile/WhiteRightArrow.svg";
import BlackLeftArrow from "../../../assets/HomePage/Black Left Arrow.svg";
import { OrderDetailModel } from "../../../services/Models/Estimator.models";
import { useNavigate } from "react-router-dom";
import { CommonUtils } from "../../../services/Common/common.utils";
import OrderDetilItem from "./OrderDetilItem";
import TrackingStepper from "./TrackingStepper";

type OrderDetailProps = {};

const TRACKING_STEPS: {
  name: string;
  date: string;
  Component?: React.FC;
}[] = [
  {
    name: "Order Received",
    date: "02-01-2024",
    Component: () => <div></div>,
  },
  {
    name: "Manufactured and Shipped",
    date: "02-03-2024",
    Component: () => <div></div>,
  },
  {
    name: "Reached Harbour - New York Harbour",
    date: "02-07-2024",
    Component: () => <div></div>,
  },
  {
    name: "Order Delivered",
    date: "02-08-2024",
    Component: () => <div> Your order has been delivered.</div>,
  },
];

const OrderDetail: FC<OrderDetailProps> = (props) => {
  const Natural = "";
  const Quartz = "";

  const defaultRowData: OrderDetailModel = {
    orderId: 221734,
    referenceName: "Kitchen Flooring Order",
    orderedDate: "12-30-2023",
    estimationDate: "01-24-2023",
    status: "Manufactured and Shipped",
    orderCurrentLocation: "New York Harbour",
    items: [
      {
        productId: 124435,
        title: "Fairy Ceramic Tile",
        skuCode: "SKU221333245133",
        imageUrl: [Natural, Quartz],
        quantity: 300,
        units: "m",
        price: 3200,
      },
      {
        productId: 124435,
        title: "Fairy Ceramic Tile",
        skuCode: "SKU221333245133",
        imageUrl: [Natural, Quartz],
        quantity: 300,
        units: "m",
        price: 3200,
      },
      {
        productId: 124435,
        title: "Fairy Ceramic Tile",
        skuCode: "SKU221333245133",
        imageUrl: [Natural, Quartz],
        quantity: 300,
        units: "m",
        price: 3200,
      },
      {
        productId: 124435,
        title: "Fairy Ceramic Tile",
        skuCode: "SKU221333245133",
        imageUrl: [Natural, Quartz],
        quantity: 300,
        units: "m",
        price: 3200,
      },
    ],
  };
  const [rowData, setRowData] = useState<OrderDetailModel>(defaultRowData);
  const [isTrackingOpen, setIsTrackingOpen] = useState<boolean>(false);

  const navigate = useNavigate();

  const handleClickBack = () => {
    if (isTrackingOpen) {
      setIsTrackingOpen(false);
      return;
    } else {
      navigate(-1);
    }
  };

  const handleItemClick = (productId: number) => {
    navigate(`/category/item/${productId}`);
  };

  return (
    <section className="estimate-deail-root">
      <header style={{ position: "relative" }}>
        <button
          className="back-arrow-btn"
          onClick={() => {
            handleClickBack();
          }}
        >
          <img src={BlackLeftArrow} alt="" />
        </button>
        <h2>Order Details</h2>
        <p>
          This order was confirmed on{" "}
          <span style={{ color: "#CF6837" }}>
            {CommonUtils.FormatDate(rowData?.orderedDate)}{" "}
          </span>
          . The shipment is expected to arrive in{" "}
          <span style={{ color: "#CF6837" }}>
            {rowData?.orderCurrentLocation}
          </span>{" "}
          by{" "}
          <span style={{ color: "#CF6837" }}>
            {CommonUtils.FormatDate(rowData?.estimationDate)}
          </span>
          .
        </p>
      </header>
      <div className="estimate-deail-body">
        <div className="estimate-deail-body-header">
          <div>
            <h3>{rowData?.referenceName}</h3>
            <span>ID : {rowData?.orderId}</span>
          </div>
          <span
            style={{
              background:
                rowData?.status === "Deliverd" ? "#009D3F" : "#ED4337",
            }}
          >
            {rowData?.status}
          </span>
        </div>
        <hr className="solid"></hr>

        <div
          className={`google-map-container ${
            isTrackingOpen ? "large-map" : ""
          }`}
        >
          {!isTrackingOpen && (
            <button
              className="tracking-btn"
              onClick={() => setIsTrackingOpen(true)}
            >
              Track Your Order <img src={WhiteRightArrow} alt="" />
            </button>
          )}
        </div>
        {!isTrackingOpen ? (
          <section id="order-list-items">
            <h3>Order Details</h3>
            {rowData?.items.map((item, index) => (
              <OrderDetilItem
                key={item.productId}
                item={item}
                sNo={index + 1}
                handleClick={handleItemClick}
              />
            ))}
            <button className="contact-sales-btn">
              Contact Sales <img src={WhiteRightArrow} alt="" />
            </button>
          </section>
        ) : (
          <div className="order-status-wraper">
            <h2>Tracking Status</h2>
            <TrackingStepper stepsConfig={TRACKING_STEPS} />
          </div>
        )}
      </div>
    </section>
  );
};

export default OrderDetail;
