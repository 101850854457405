import React, { useMemo, useState } from "react";
import "./Cards.css";
import InnerCards from "./InnerCards/InnerCards";
import Innovation from "../../../assets/aboutUs/Group.svg";
import Sustainability from "../../../assets/aboutUs/Frame 1000001905.svg";
import Empowerment from "../../../assets/aboutUs/Frame 1000001908.svg";
import CustomerFirst from "../../../assets/aboutUs/Frame 1000001910.svg";
import Transparency from "../../../assets/aboutUs/Group (1).svg";
import Accountability from "../../../assets/aboutUs/Group (3).svg";
import Quality from "../../../assets/aboutUs/aboutus quality.svg";
import Growth from "../../../assets/aboutUs/aboutus growth.svg";
import Adaptability from "../../../assets/aboutUs/aboutus adapdability.svg";
import Global from "../../../assets/aboutUs/aboutus global.svg";
import Integrity from "../../../assets/aboutUs/Group (2).svg";
import Community from "../../../assets/aboutUs/Frame 1000001914.svg";

interface CardValue {
  key: number;
  title: string;
  icon: string;
  summary: string;
}

const cardValues: CardValue[][] = [
  [
    {
      key: 0,
      title: "Innovation",
      icon: Innovation,
      summary:
        "Embrace technology and creative solutions to continuously improve and stay ahead in the industry.",
    },
    {
      key: 1,
      title: "Sustainability",
      icon: Sustainability,
      summary:
        "Prioritize environmentally responsible practices to reduce the environmental impact of the construction materials supply chain.",
    },
    {
      key: 2,
      title: "Quality",
      icon: Quality,
      summary:
        "Strive for excellence in all aspects of our business, ensuring that the materials we provide meet the highest standards.",
    },
    {
      key: 3,
      title: "Customer First",
      icon: CustomerFirst,
      summary:
        "Put our customers first by understanding their needs, providing exceptional service, and building lasting relationships.",
    },
    {
      key: 4,
      title: "Global",
      icon: Global,
      summary:
        "Foster collaboration and cultural understanding to facilitate seamless cross-border trade.",
    },
    {
      key: 5,
      title: "Empowerment",
      icon: Empowerment,
      summary:
        "Encourage and support the growth and development of our team and partners, valuing their contributions and ideas.",
    },
    {
      key: 6,
      title: "Accountability",
      icon: Accountability,
      summary:
        "Take responsibility for your actions and decisions, ensuring that promises and commitments are met.",
    },
    {
      key: 7,
      title: "Transparency",
      icon: Transparency,
      summary:
        "Communicate openly and honestly with all stakeholders, from customers to investors, building trust through transparency.",
    },
    {
      key: 8,
      title: "Growth",
      icon: Growth,
      summary:
        "Cultivate a culture of continuous learning and development, embracing challenges as opportunities for growth.",
    },
    {
      key: 9,
      title: "Adaptability",
      icon: Adaptability,
      summary:
        "Be agile and open to change, as the industry and global markets evolve.",
    },
    {
      key: 10,
      title: "Community",
      icon: Community,
      summary:
        "Give back to the communities we operate in and support social responsibility initiatives.",
    },
    {
      key: 11,
      title: "Integrity",
      icon: Integrity,
      summary:
        "Guiding every action with unwavering honesty and ethical principles.",
    },
  ],
];

interface CardsProps {
  type: "primary" | "secondary" | "other";
  icon?: string;
  title: string;
  summary: string[];
}

const Cards: React.FC<CardsProps> = ({ type, icon, title, summary }) => {
  const [selectedCardIndex, setSelectedCardIndex] = useState(0);

  const combinedObject = useMemo(() => {
    let val: CardValue[] = [];
    cardValues.forEach((value, index) => {
      value.forEach((value1, index1) => {
        val.push(value1);
      });
    });
    return val;
  }, []);

  return (
    <div
      className="card-root"
      style={{
        backgroundColor:
          type === "primary"
            ? "#EFEFEF"
            : type === "secondary"
            ? "#CF6837"
            : "#222222",
      }}
    >
      <div className="inner-first">
        <div
          className="inner-title"
          style={{ color: type === "primary" ? "#222222" : "#FFFFFF" }}
        >
          {title}
        </div>
        <div className="card-image-wrapper">
          {type !== "other" ? (
            <div className="card-image">
              <img className="image-card" src={icon} alt={title}></img>
            </div>
          ) : (
            <>
              <div className="value-wrapper">
                <div className="value-title">
                  {
                    combinedObject.filter(
                      (value, index) => value.key === selectedCardIndex
                    )[0].title
                  }
                </div>
                <div className="value-subtitle">
                  {
                    combinedObject.filter(
                      (value, index) => value.key === selectedCardIndex
                    )[0].summary
                  }
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {type !== "other" ? (
        <div className="inner-summary-wrapper">
          <span
            className="inner-summary"
            style={{ color: type === "primary" ? "#222222" : "#F4E6D0" }}
          >
            {summary[0]}
          </span>
          <span
            className="inner-summary"
            style={{ color: type === "primary" ? "#222222" : "#F4E6D0" }}
          >
            {summary[1]}
          </span>
          <span
            className="inner-summary middle-summary"
            style={{ color: type === "primary" ? "#CF6837" : "#F4E6D0" }}
          >
            {summary[2]}
          </span>
          <span
            className="inner-summary"
            style={{ color: type === "primary" ? "#222222" : "#F4E6D0" }}
          >
            {summary[3]}
          </span>
        </div>
      ) : (
        <>
          <div className="innercardwrapper">
            {cardValues.map((value, index) => (
              <div className="innercards" key={index}>
                {value.map((value1, index1) => (
                  <InnerCards
                    key={index1}
                    subTitle={value1.summary}
                    onClick={() => {
                      setSelectedCardIndex(value1.key);
                    }}
                    title={value1.title}
                    active={selectedCardIndex === value1.key}
                    icon={value1.icon}
                  ></InnerCards>
                ))}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default Cards;
