import React, { FC } from "react";
import { OrdersListItemModel } from "../../../services/Models/Estimator.models";
import "../Estimates/EstimatesListItem.css";
import RightArrow from "../../../assets/Right Arrow.svg";
import { Link, useNavigate } from "react-router-dom";

type OrdersListItemProps = {
  item: OrdersListItemModel;
  sNo: number;
};

const OrdersListItem: FC<OrdersListItemProps> = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="estimates-list-item"
      onClick={() => navigate(`/profile/orders/${props.item.orderId}`)}
    >
      <div>
        <span>S.NO</span>
        <h2>{props.sNo}</h2>
      </div>
      <div className="estimate-thumbnails">
        {props.item.imgUrls.map((img, index) => {
          if (index < 4)
            return (
              <img
                key={index}
                src={img}
                alt=""
                className="skeleton-img-loader"
                loading={"lazy"}
              />
            );
          if (index === 4)
            return <div key={index}>+{props.item.imgUrls.length - 4}</div>;
          else return "";
        })}
      </div>
      <div>
        <span>ID : {props.item.orderId}</span>
        <h2>{props.item.referenceName}</h2>
      </div>
      <div>
        <span>Date</span>
        <h3>{props.item.date}</h3>
      </div>
      {/* <div></div> */}
      <div>
        <span>Status</span>
        <h3
          style={{
            color: props.item.status !== "Deliverd" ? "#ED4337" : "#009D3F",
          }}
        >
          {props.item.status}
        </h3>
      </div>
      {/* <div></div> */}
      <Link to={`/profile/estimates/${props.item.orderId}`}>
        View <img src={RightArrow} alt="" />
      </Link>
    </div>
  );
};

export default OrdersListItem;
