import "./Footer.css";
import Logo from "../../assets/Footer/Matbook Logo.svg";
import LogoFliped from "../../assets/Footer/Banner Logo Flipped.svg";
import LogoWithText from "../../assets/Footer/Logo + Text.svg";
import UpRightArrow from "../../assets/HomePage/Up Right Arrow.svg";
import { Link, useNavigate } from "react-router-dom";
import { useAppSelector } from "../../store/Store";

const Footer = () => {
  const navigate = useNavigate();
  const categories = useAppSelector((state) => state.categories);

  const scrollToSection = (sectionId: string, maxAttempts = 25) => {
    navigate("/");
    return new Promise<void>((resolve, reject) => {
      const attemptScroll = (attempt = 1) => {
        const section = document.querySelector(sectionId);
        if (section) {
          section.scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "center",
          });
          resolve();
        } else if (attempt < maxAttempts) {
          setTimeout(() => {
            attemptScroll(attempt + 1);
          }, 100);
        }
      };

      attemptScroll();
    });
  };

  return (
    <div className="footer">
      <div className="top-frame">
        <img src={Logo} className="top-frame-svg1" alt="" />
        <img src={LogoFliped} className="top-frame-svg2" alt="" />
        <Link className="footer-button" to={"/contact"}>
          <p>Get An Estimate </p>
          <img src={UpRightArrow} alt="" />
        </Link>
      </div>
      <div className="bottom-frame">
        <div className="footer-logo">
          <img src={LogoWithText} alt="logo" />
          {/* <h1>MATBOOK</h1> */}
        </div>
        <div className="footer-links-wraper">
          <div className="footer-links footer-about">
            <Link to="/">Home</Link>
            {/* <a onClick={() => scrollToSection("#categories")}>Categories</a> */}
            <a onClick={() => scrollToSection("#categories")}>Catalog</a>
            <Link to="/aboutus">About Us</Link>
            <Link to="/contact">Contact Us</Link>
          </div>

          <div className="footer-links footer-marble">
            {categories.map((item) => (
              <Link
                key={item.productCategoryId}
                to={
                  item.productCategoryName
                    .toLocaleLowerCase()
                    .includes("quartz")
                    ? "category/" + item.productCategoryId
                    : "category/" + item.productCategoryId
                }
              >
                {item.productCategoryName}
              </Link>
            ))}
          </div>

          <div className="footer-links footer-socials">
            {/* <a>Instagram</a> */}
            <a href="https://www.linkedin.com/company/matbook/">LinkedIn</a>
            <a href="mailto:info@thematbook.com">info@thematbook.com</a>
            <a href="tel:+12177219563">+1 (217) 721-9563</a>
          </div>
        </div>
        <p className="year">&#169; 2024 MatBook Inc.</p>
      </div>
    </div>
  );
};
export default Footer;
