import React, { useState } from "react";
import "./Contact.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import RightArrow from "../../assets/Submit Right Arrow.svg";
import { ContactModel } from "../../services/Models/Common.models";
import { BusyIndicator } from "../HelperComps/BusyIndicator";
import { ContactService } from "../../services/API/contact.service";

const Contact: React.FC = () => {
  const [rowData, setRowData] = useState<ContactModel>({} as ContactModel);
  const [isLoading, setIsloading] = useState<boolean>(false);

  const contactService = ContactService.Instance;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async () => {
    setIsloading(true);
    await contactService
      .SendContactRequest(rowData)
      .then((res) => {
        setIsloading(false);
        setRowData({
          name: "",
          email: "",
          phone: 0,
          companyName: "",
          message: "",
        });
        toast.success("Message sent successfully");
      })
      .catch((err) => {
        setIsloading(false);
        toast.error(err.message);
      });
  };

  return (
    <div className="contact">
      <BusyIndicator loading={isLoading} />
      <h1>
        Get In <span>Touch</span> With Us
      </h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <label>
          Name*
          <input
            type="text"
            value={rowData?.name}
            name="name"
            placeholder="Name"
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Email ID*
          <input
            type="email"
            value={rowData?.email}
            name="email"
            placeholder="example@gmail.com"
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Phone Number*
          <input
            type="tel"
            value={rowData?.phone}
            name="phone"
            placeholder="+1 217 721 9563"
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Company Name
          <input
            type="text"
            value={rowData?.companyName}
            name="company"
            placeholder="ACME Corporation"
            onChange={handleChange}
            required
          />
        </label>
        <label>
          Message
          <textarea
            // type="text"
            value={rowData?.message}
            name="message"
            className="message"
            placeholder="Your message here"
            onChange={(e) =>
              setRowData({ ...rowData, message: e.target.value })
            }
            required
          />
        </label>
        <button type="submit" className="submit">
          <h3>Submit</h3>
          <img src={RightArrow} alt="" />
        </button>
      </form>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="240"
        height="469"
        viewBox="0 0 240 469"
        fill="none"
        className="contact-left-vector"
      >
        <path
          d="M0 187.6C117.533 185.945 117.533 187.6 117.533 0C117.533 187.6 117.533 187.6 239.586 187.6C117.533 187.6 117.533 187.6 117.533 469C117.533 187.6 117.533 185.945 0 187.6Z"
          fill="#EFEFEF"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="63"
        height="122"
        viewBox="0 0 63 122"
        fill="none"
        className="contact-right-vector"
      >
        <path
          opacity="0.1"
          d="M0 48.7755C30.5581 48.3451 30.5581 48.7755 30.5581 0C30.5581 48.7755 30.5581 48.7755 62.2916 48.7755C30.5581 48.7755 30.5581 48.7755 30.5581 121.939C30.5581 48.7755 30.5581 48.3451 0 48.7755Z"
          fill="#EFEFEF"
        />
      </svg>
    </div>
  );
};

export default Contact;
