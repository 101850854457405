import { HTTP_METHODS } from "../Common/common.constants";
import { CommonService } from "../Common/common.service";
import {
  CategoryDetailModel,
  CategoryListPostModel,
  CategoryModel,
  ItemDetailModel,
  ItemModel,
} from "../Models/Categories.model";

export class CategoryService {
  private _baseUrl = process.env.REACT_APP_BASE_API_URL;
  private serviceUrl = `${this._baseUrl}/product`;
  private static _instance: CategoryService;

  public static get Instance(): CategoryService {
    return this._instance || (this._instance = new this());
  }

  async GetCategories(): Promise<CategoryModel[]> {
    let result!: CategoryModel[];
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(`${this._baseUrl}productCategory/getAllCategories`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async GetFeaturedProducts(payload?: CategoryListPostModel): Promise<{
    data: ItemModel[];
    pageNumber: number;
    pageSize: number;
    total: number;
  }> {
    let result!: {
      data: ItemModel[];
      pageNumber: number;
      pageSize: number;
      total: number;
    };
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(
      `${this.serviceUrl}/featuredProducts?pageSize=${
        payload?.pageSize ?? 10
      }&pageNumber=${payload?.pageNo ?? 0}`,
      requestInfo
    )
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async GetCategoryProducts(
    payload: CategoryListPostModel
  ): Promise<CategoryDetailModel> {
    let result!: CategoryDetailModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(
      `${this.serviceUrl}/${payload?.productCategoryId}?pageSize=${payload?.pageSize}&pageNumber=${payload?.pageNo}`,
      requestInfo
    )
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async GetProductList(
    payload?: CategoryListPostModel
  ): Promise<CategoryDetailModel> {
    let result!: CategoryDetailModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    // const url = `${this.serviceUrl}/product/list?productCategoryId=${payload.productCategoryId}&pageNo=${payload.pageNo}&pageSize=${payload.pageSize}`
    const url = "https://mocki.io/v1/01134eaa-5ebb-488b-a982-fc374063ae7b";
    await fetch(url, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getProductDetail(sukCode: string): Promise<ItemDetailModel> {
    let result!: ItemDetailModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    const url = `${this.serviceUrl}/productDetail/${sukCode}`;
    await fetch(url, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }
}
