import React, { FC } from "react";
import { PopupDialog } from "../../HelperComps/PopupDialog";
import Tooltip from "../../HelperComps/Tooltip";
import InfoIcon from "../../../assets/Estimator/info_icon.svg";
import { EstimatorModel } from "../../../services/Models/Estimator.models";

interface ContactDetailsProps {
  data: EstimatorModel;
  setData: React.Dispatch<React.SetStateAction<EstimatorModel>>;
  open: boolean;
  handleClose: any;
  handleSubmit?: any;
}

export const ContactDetails: FC<ContactDetailsProps> = (props) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setData({ ...props.data, [e.target.name]: e.target.value });
  };

  return (
    <PopupDialog
      open={props.open}
      dialogTile="Enter Your Contact Information"
      handleClose={() => props.handleClose()}
      hideSubmit={true}
      helperText="Your samples will be sent to you in about 7 days"
    >
      <form
        className="popup-childern-wraper"
        onSubmit={(e) => {
          e.preventDefault();
          props.handleSubmit();
        }}
      >
        <label htmlFor="user-detail">
          <h1>Basic Information</h1>
        </label>
        <div id="user-detail">
          <input
            type="text"
            placeholder="Name"
            value={props.data.name}
            name="name"
            required
            onChange={handleChange}
          />
          <input
            type="email"
            placeholder="Email Id"
            value={props.data.email}
            name="email"
            required
            onChange={handleChange}
          />
          <input
            type="number"
            placeholder="Phone No."
            value={props.data.phone}
            name="phone"
            required
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Company Name"
            value={props.data.companyName}
            name="companyName"
            required
            onChange={handleChange}
          />
        </div>

        <hr className="solid"></hr>

        <label htmlFor="user-address">
          <h1>Address</h1>
        </label>
        <div id="user-address">
          <input
            type="text"
            placeholder="Locality"
            value={props.data?.locality}
            name="locality"
            required
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="Street"
            value={props.data?.street}
            name="street"
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="City"
            value={props.data?.city}
            name="city"
            required
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="State"
            value={props.data?.state}
            name="state"
            required
            onChange={handleChange}
          />
          <input
            type="number"
            placeholder="Zipcode"
            value={props.data?.zipcode}
            name="zipcode"
            required
            onChange={handleChange}
            maxLength={6}
          />
        </div>

        <hr className="solid"></hr>

        <div className="estimator-reference">
          <input
            type="text"
            placeholder="Estimate Reference Name"
            required
            value={props.data?.estimationRefName}
            name="estimationRefName"
            onChange={handleChange}
          />
          <div className="tooltip-wraper">
            <Tooltip title="This is a reference name which you can use to identify this estimate request later on">
              <img src={InfoIcon} alt="" />
            </Tooltip>
          </div>
        </div>
        <hr className="solid"></hr>
        <button type="submit" className="estimate-submit">
          Get Estimation And Free Samples
        </button>
      </form>
    </PopupDialog>
  );
};
