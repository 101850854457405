import React, { FC, memo, useRef, useState } from "react";
import "./ItemList.css";
import { ItemModel } from "../../../services/Models/Categories.model";
import { ItemCard } from "./ItemCard";
import { useNavigate } from "react-router-dom";

interface CategoryItemListProps {
  categoryTitle: string;
  itemList: ItemModel[];
  titleSize?: string;
  isTwoitemsPerRow?: boolean;
}

const CotegoryItemList: FC<CategoryItemListProps> = memo((props) => {
  const navigate = useNavigate();

  const [mouseDown, setMouseDown] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scroll, setScroll] = useState(0);

  const sliderRef = useRef<HTMLDivElement>(null);

  const startDragging = (e: React.MouseEvent<HTMLDivElement>) => {
    if (sliderRef.current) {
      setMouseDown(true);
      setStartX(e.pageX - sliderRef.current.offsetLeft);
      setScroll(sliderRef.current.scrollLeft);
    }
  };

  const stopDragging = () => {
    setMouseDown(false);
  };

  const move = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (!mouseDown || !sliderRef.current) {
      return;
    }
    const x = e.pageX - sliderRef.current.offsetLeft;
    const scrollX = x - startX;
    sliderRef.current.scrollLeft = scroll - scrollX;
  };

  const scrollLeft = () => {
    if (sliderRef.current) {
      const currentScrollLeft = sliderRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft - 750; // You can adjust the scroll amount as needed

      if (newScrollLeft < 1) {
        setScroll(0);
      }
      sliderRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    if (sliderRef.current) {
      const currentScrollLeft = sliderRef.current.scrollLeft;
      const newScrollLeft = currentScrollLeft + 750; // You can adjust the scroll amount as needed

      setScroll((scroll) => scroll + 1);
      sliderRef.current.scrollTo({
        left: newScrollLeft,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (productId: number) => {
    navigate(`/category/item/${productId}`);
  };

  return (
    <div className="categories-list-wraper">
      <div className="catogary-list-header">
        <h2 style={{ fontSize: props.titleSize ?? "" }}>
          {props.categoryTitle}
        </h2>
      </div>
      <div
        className="item-list-wraper"
        ref={sliderRef}
        onMouseMove={move}
        onMouseDown={startDragging}
        onMouseUp={stopDragging}
        onMouseLeave={stopDragging}
      >
        <div className="left-space-mobile"></div>
        {props.itemList?.map((item, index) => (
          <ItemCard
            item={item}
            key={index}
            handleClick={handleClick}
            isTwoitemsPerRow={props.isTwoitemsPerRow}
          />
        ))}
      </div>
      {scroll > 0 && <button className="scrolling-btn1" onClick={scrollLeft} />}
      <button className="scrolling-btn2" onClick={scrollRight} />
    </div>
  );
});
export default CotegoryItemList;
