import React, { FC } from "react";
import "./Categories.css";
import { CategoryModel } from "../../../services/Models/Categories.model";
import Star from "../../../assets/Catalog Home/Start Icon.svg";
import { useNavigate } from "react-router-dom";

interface CategoriesProps {
  categories: CategoryModel[];
}

const Categories: FC<CategoriesProps> = (props) => {
  const navigate = useNavigate();

  const handleClick = (productCategoryId: number) => {
    // if (
    //   props.categories
    //     .find((f) => f.productCategoryId === productCategoryId)
    //     ?.productCategoryName.toLowerCase()
    //     .includes("quartz")
    // ) {
      navigate(`/category/${productCategoryId}`);
    // } else {
    //   navigate("/contact");
    // }
  };

  return (
    <div className="categories-wraper">
      {props?.categories?.map((item, index) => (
        <div
          key={index}
          className="category-item"
          onClick={() => handleClick(item.productCategoryId ?? 0)}
        >
          <div className="category-item-text">
            <h2>{item.productCategoryName}</h2>
            <button>View All ➔</button>
          </div>
          <img
            className="skeleton-img-loader"
            src={process.env.REACT_APP_S3_BASE_URL + item.imageUrl}
            alt="category img"
            loading={"lazy"}
          />
          <img className="hover-icon1" src={Star} alt="" />
          <img className="hover-icon2" src={Star} alt="" />
        </div>
      ))}
    </div>
  );
};
export default Categories;
