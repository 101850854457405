import React, { FC } from "react";
import "./OrderDetilItem.css";
import { OrderDetailItemModel } from "../../../services/Models/Estimator.models";
import RightArrow from "../../../assets/Right Arrow.svg";

type OrderDetilItemProps = {
  sNo: number;
  item: OrderDetailItemModel;
  handleClick: (productId: number) => void;
};

const OrderDetilItem: FC<OrderDetilItemProps> = (props) => {
  return (
    <div
      className="order-detail-item-root"
      onClick={() => props.handleClick(props.item.productId)}
    >
      <div>
        <span>Sr. No</span>
        <h2>{props.sNo}.</h2>
      </div>
      <div>
        <img
          className="skeleton-img-loader"
          src={props.item.imageUrl[0]}
          alt=""
          style={{
            width: 104,
            height: 64,
            borderRadius: 8,
          }}
        />
      </div>
      <div>
        <span>SUK: {props.item.skuCode}</span>
        <h2>{props.item.title}</h2>
      </div>
      <div>
        <span>Quantity</span>
        <h3>
          {props.item.quantity} {props.item.units}
          <sup>2</sup>
        </h3>
      </div>
      <div>
        <span>Price</span>
        <h3>${props.item.price}</h3>
      </div>
      <div>
        <button>
          View Product <img src={RightArrow} alt="" />
        </button>
      </div>
    </div>
  );
};

export default OrderDetilItem;
