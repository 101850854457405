import React, { FC, useState } from "react";
import "./PopupDialog.css";
import RightArrow from "../../assets/Submit Right Arrow.svg";
import CloseBtn from "../../assets/Close Button.svg";

interface PopupDialogProps {
  children?: string | JSX.Element | JSX.Element[];
  open: boolean;
  dialogTile: string;
  handleClose: any;
  handleSubmit?: any;
  hideSubmit?: boolean;
  submitText?: string;
  helperText?: string;
  hideHeaderFooter?: boolean;
}

export const PopupDialog: FC<PopupDialogProps> = (props) => {
  const [isClosing, setIsClosing] = useState(false);

  const handleClose = () => {
    setIsClosing(true);
    // Optionally, you can add a delay to wait for the animation to complete before actually closing the popup
    setTimeout(() => {
      setIsClosing(false);
      props.handleClose();
    }, 500); // 500ms is the duration of the animation
  };

  if (!props.open) return null;

  return (
    <main className="popup-root" onClick={handleClose}>
      <div
        className={`popup-wraper ${isClosing ? "slide-down" : ""}`}
        onClick={(e) => e.stopPropagation()}
      >
        <button className="close-btn" onClick={handleClose}>
          <img src={CloseBtn} alt="" />
        </button>
        {!props.hideHeaderFooter ? (
          <div style={{ width: "100%", maxWidth: "100%" }}>
            <header>
              <h1>{props.dialogTile}</h1>
            </header>
            <hr className="solid"></hr>

            {props.children}

            {/* <hr className="solid"></hr> */}
            <footer>
              {props.hideSubmit ? (
                ""
              ) : (
                <button
                  className="submit-btn"
                  onClick={() => props.handleSubmit()}
                >
                  {props.submitText ?? "Submit"}
                  <img src={RightArrow} alt="" />
                </button>
              )}
              {props.helperText && <span>{props.helperText}</span>}
            </footer>
          </div>
        ) : (
          <div style={{ width: "100%", maxWidth: "100%" }}>
            {props.children}
          </div>
        )}
      </div>
    </main>
  );
};
