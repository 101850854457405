import React from "react";
import IMG404 from "../../assets/404/404.png";

type Props = {};

const Missing = (props: Props) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <img
        className="skeleton-img-loader"
        src={IMG404}
        alt="404 page not founf"
        style={{
          width: "400px",
          height: "100%",
          objectFit: "contain",
        }}
        loading={"lazy"}
      />
      <h1 style={{ fontSize: "10vh", textAlign: "center" }}>Page Not Found</h1>
    </div>
  );
};

export default Missing;
