import React, { FC, useState } from "react";
import "../Estimates/EstimatesList.css";
import { OrdersListModel } from "../../../services/Models/Estimator.models";
// import NeroMarquina from "../../../assets/Categories/Quartz/Nero Marquina - Full Slab.jpg";
// import Natural from "../../../assets/Categories/Natural Stone.jpg";
// import Quartz from "../../../assets/Categories/Quartz.jpg";
// import Ceramic from "../../../assets/Categories/Ceramic Tiles.jpg";
import Pagination from "../../HelperComps/Pagination";
import OrdersListItem from "./OrdersListItem";

type OrdersListProps = {};

const OrdersList: FC<OrdersListProps> = (props) => {
  const Natural = "";
  const Quartz = "";
  const NeroMarquina = "";
  const Ceramic = "";

  const defaultRowData: OrdersListModel = {
    items: [
      {
        orderId: 2144321,
        date: "19-01-2024",
        referenceName: "Nero Marquina Estimate",
        status: "Order Received",
        imgUrls: [NeroMarquina],
      },
      {
        orderId: 2143902,
        date: "02-01-2024",
        referenceName: "Kitchen Flooring Estimate",
        status: "Manufactured and Shipped",
        imgUrls: [Ceramic, Natural, Quartz, Natural, Quartz, Ceramic],
      },
      {
        orderId: 2142018,
        date: "27-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "In Transit",
        imgUrls: [Quartz, Natural],
      },
      {
        orderId: 2143813,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Deliverd",
        imgUrls: [Ceramic, Natural, Quartz, Natural],
      },
      {
        orderId: 2144321,
        date: "14-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        orderId: 2144321,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        orderId: 2144321,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        orderId: 2144321,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        orderId: 2144321,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        orderId: 2144321,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
      {
        orderId: 2144321,
        date: "21-12-2023",
        referenceName: "Kitchen Flooring Estimate",
        status: "Order Received",
        imgUrls: [Natural, Quartz, Ceramic],
      },
    ],
    pageNo: 1,
    pageSize: 5,
    totalItems: 11,
  };
  const [rowData, setRowData] = useState<OrdersListModel>(defaultRowData);
  const indexOfLastItem = rowData.pageNo * rowData.pageSize;
  const indexOfFirstItem = indexOfLastItem - rowData.pageSize;
  const currentItems = rowData.items
    ? rowData.items.slice(indexOfFirstItem, indexOfLastItem)
    : [];

  return (
    <section className="estimator-list-root">
      <header className="estimator-list-header">
        <h2>Your Orders</h2>
        <p>
          This is the list of all your orders. Please select one order to view
          its delivery details and estimated time of arrival
        </p>
      </header>
      <div className="filters-wraper">
        <input type="search" placeholder="Search..." />
        <div>
          <div className="select-wraper">
            <select name="filter" id="filter">
              <option value="all">All</option>
              <option value="Filter1">Filter1</option>
              <option value="Filte2">Filte2</option>
              <option value="None">None</option>
            </select>
          </div>
          <div className="select-wraper">
            <select name="sort" id="sort">
              <option value="latest">Latest</option>
              <option value="latest">Oldest</option>
              <option value="latest">Size</option>
              <option value="latest">Date</option>
            </select>
          </div>
        </div>
      </div>
      <div className="estimator-list-items-wraper">
        {currentItems?.map((item, index) => (
          <OrdersListItem
            key={item.orderId}
            item={item}
            sNo={rowData.pageSize * (rowData.pageNo - 1) + index + 1}
          />
        ))}
      </div>
      <Pagination rowData={rowData} setRowData={setRowData} />
    </section>
  );
};

export default OrdersList;
