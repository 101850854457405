import React, { FC, useEffect, useRef, useState } from "react";
import "./Estimator.css";
import { toast } from "react-toastify";
import EST from "../../../assets/Estimator/Estimator.svg";
import Upload from "../../../assets/Catalog Home/Upload icon.svg";
import EmptyCart from "../../../assets/Estimator/Empty cart 1.svg";
import IMG1 from "../../../assets/Estimator/Estimator Footer 1.svg";
import IMG2 from "../../../assets/Estimator/Estimator Footer 2.svg";
import IMG3 from "../../../assets/Estimator/Estimator Footer 3.svg";
import IMG4 from "../../../assets/Estimator/Estimator Footer 4.svg";
import { ItemCard } from "../../Catalog/Components/ItemCard";
import UpRightArrow from "../../../assets//HomePage/White Up Right Arrow.svg";
import UpRightArrow2 from "../../../assets/HomePage/Up Right Arrow.svg";
import { useNavigate } from "react-router-dom";
import { ContactDetails } from "./ContactDetails";
import { useAppDispacth, useAppSelector } from "../../../store/Store";
import { clearAll, removeFromCart } from "../../../store/features/CartSlice";
import { EstimatorModel } from "../../../services/Models/Estimator.models";
import { BusyIndicator } from "../../HelperComps/BusyIndicator";
import { EstimateService } from "../../../services/API/estimate.service";
import ReactS3Client from "react-aws-s3-typescript";
import AuthService from "../../../auth/auth.service";

export const EstimatorFooterItems = [
  {
    img: IMG1,
    text: "Delve deeper into diverse materials for insights",
  },
  {
    img: IMG2,
    text: "Share documentsfor estimates, receive prompts,obligation free responses",
  },
  {
    img: IMG3,
    text: "Get free samples, receive team assistance,  seamless doorstep delivery.",
  },
  {
    img: IMG4,
    text: "Monitor your order's progress with live, real-time tracking and updates.",
  },
];

interface EstimatorProps {
  sukId?: string;
}

const Estimator: FC<EstimatorProps> = (props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispacth();
  const cartItems = useAppSelector((state) => state.cart.items);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);

  const defaultRowData: EstimatorModel = {
    zipcode: undefined,
    products: cartItems,
    area: {
      size: undefined,
      unit: "m",
    },
    floorplanPath: "",
    estimationRefName: "",
  };
  // const defaultRowData = {} as EstimatorModel;
  const [rowData, setRowData] = useState<EstimatorModel>(defaultRowData);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const fileInputRef = useRef<HTMLInputElement>(null);
  const currentUser = AuthService.getCurrentUser();
  const estimateService = EstimateService.Instance;

  const handleClick = (productId: number) => {
    navigate(`/category/item/${productId}`);
  };

  const handleRemoveItem = (productId: number) => {
    const itemInCart = cartItems.find((f) => f.productId === productId);

    if (itemInCart) {
      dispatch(removeFromCart({ productId: itemInCart.productId }));
    } else return;
  };

  useEffect(() => {
    return setRowData((state) => ({ ...state, products: cartItems }));
  }, [cartItems]);

  const handleFileUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    setIsLoading(true);

    let file!: File, fileName;
    if (fileInputRef.current && fileInputRef.current.files) {
      file = fileInputRef.current.files[0];
      fileName = fileInputRef.current.files[0].name;
    }

    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME ?? "",
      dirName: process.env.REACT_APP_DIR_NAME ?? "",
      region: process.env.REACT_APP_REGION ?? "",
      accessKeyId: process.env.REACT_APP_ACCESS_KEY_ID ?? "",
      secretAccessKey: process.env.REACT_APP_SECRET_ACCESS_KEY ?? "",
    };

    const s3 = new ReactS3Client(config);

    await s3
      .uploadFile(file, fileName)
      .then((res) => {
        setRowData({ ...rowData, floorplanPath: res.location });
        setIsLoading(false);
        toast.success("File uploaded successfully");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error("Error uploading file: " + err.message);
      });
  };

  const handleOpenDialog = (event: React.FormEvent) => {
    event.preventDefault();

    if (!(currentUser?.["MAT-TOKEN"] || currentUser?.email)) {
      toast.error("You need to login first");
      navigate("/auth/login");
      return;
    } else {
      setRowData((state) => ({ ...state, email: currentUser?.email }));
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmit = async () => {
    setIsLoading(true);

    await estimateService
      .createEstimate(rowData)
      .then((res) => {
        dispatch(clearAll());
        setIsLoading(false);
        toast.success("Estimation request placed successfully");
        handleCloseDialog();
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
        handleCloseDialog();
      });
  };

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  return (
    <div className="estimator-root">
      <BusyIndicator loading={isLoading} />
      <header className="estimator-header">
        <div>
          <h1>Estimator</h1>
          <p>
            Select the products which you like, and we will send you a
            <span> free sample</span> along with a <span> cost estimate</span>
            for each of them.
          </p>
        </div>
        <img src={EST} alt="" />
      </header>
      {rowData?.products.length > 0 ? (
        <form action="" className="estimator-form">
          <label htmlFor="order-detail">
            <h1>Quantity Required</h1>
          </label>
          <div id="order-detail">
            <div className="area-input">
              <input type="number" placeholder="Area" />
              <div className="units-div">
                <span
                  className={`${rowData?.area.unit === "m" ? "isUnit" : ""}`}
                  onClick={() =>
                    setRowData({
                      ...rowData,
                      area: { size: rowData.area.size, unit: "m" },
                    })
                  }
                >
                  m<sup>2</sup>
                </span>
                /
                <span
                  className={`${rowData?.area?.unit === "f" ? "isUnit" : ""}`}
                  onClick={() =>
                    setRowData({
                      ...rowData,
                      area: { size: rowData.area.size, unit: "f" },
                    })
                  }
                >
                  f<sup>2</sup>
                </span>
              </div>
            </div>
            <span>-or-</span>
            <input type="number" placeholder="Units" />
            <span>-or-</span>
            <label htmlFor="files" className="upload-btn">
              Upload Floorplan
              <img src={Upload} alt="" />
              <input
                id="files"
                style={{ display: "none" }}
                type="file"
                ref={fileInputRef}
                onChange={handleFileUpload}
              />
            </label>
            <span>
              (Add your floorplan and we will estimate the quantity for you! )
            </span>
          </div>
          <label>
            <h1>Selected products</h1>
          </label>
          <div className="selected-items-wraper">
            {rowData?.products.map((item, index) => (
              <ItemCard
                key={item.productId}
                item={item}
                handleClick={handleClick}
                helperFuntion={() => handleRemoveItem(item.productId)}
                isEstimatorPage={true}
                isTwoitemsPerRow={dimensions.width <= 480}
              />
            ))}
          </div>
          <button
            onClick={(e) => handleOpenDialog(e)}
            disabled={!(rowData?.products.length > 0)}
            style={{
              background: rowData?.products.length > 0 ? "" : "#a2a2a250",
              cursor: rowData?.products.length > 0 ? "pointer" : "not-allowed",
            }}
          >
            Estimate{" "}
            {`${cartItems.length > 0 ? "(" + cartItems.length + ")" : ""}`}{" "}
            Products <img src={UpRightArrow} alt="" />
          </button>
        </form>
      ) : (
        <div className="no-items-text">
          <img src={EmptyCart} alt="Empty cart" />
          <h2>Uh Oh! You Do Not Have Any Products Added To The Estimator</h2>
          <p>Browse Our Catalog To Add Your Favourite Products</p>
          <button className="" onClick={() => navigate("/catalog")}>
            View Catalog <img src={UpRightArrow2} alt="" />
          </button>
        </div>
      )}
      <div className="estimator-footer">
        {EstimatorFooterItems.map((item, index) => (
          <div className="estimator-footer-item" key={index}>
            <img src={item.img} alt={item.text} loading={"lazy"} />
            <p>{item.text}</p>
          </div>
        ))}
      </div>
      <ContactDetails
        open={openDialog}
        data={rowData}
        setData={setRowData}
        handleClose={handleCloseDialog}
        handleSubmit={handleSubmit}
      />
    </div>
  );
};

export default Estimator;
