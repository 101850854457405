import React, { useState, useRef, useEffect } from "react";
import MatbookLogo from "../../assets/Frame 9.svg";
import DropdownSvg from "../../assets/Vector (2).svg";
import addIcon from "../../assets/Catalog Home/ItemDetail Add to Estimator icon.svg";
import HamIcon from "../../assets/Frame (4) copy 2.svg";
import ProfileIcon from "../../assets/Navbar/Profile_icon.svg";
import "./Navbar.css";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useAppSelector } from "../../store/Store";
import AuthService from "../../auth/auth.service";
import { useDispatch } from "react-redux";
import { CategoryService } from "../../services/API/category.service";
import { allCategories } from "../../store/features/CategorySlice";
import { CategoryModel } from "../../services/Models/Categories.model";
import { toast } from "react-toastify";
import { BusyIndicator } from "../HelperComps/BusyIndicator";

interface Option {
  title: string;
  dropdown: boolean;
  button?: boolean;
  isProfile?: boolean;
  icon?: string;
  link: string;
}

const options: Option[] = [
  {
    title: "Home",
    dropdown: false,
    link: "/",
  },
  {
    title: "Contact Us",
    dropdown: false,
    link: "/contact",
  },
  // {
  //   title: "About Us",
  //   dropdown: false,
  //   link: "/aboutus",
  // },
  {
    title: "Catalog",
    dropdown: false,
    link: "/catalog",
  },
  {
    title: "Categories",
    dropdown: true,
    link: "/category",
  },
  {
    title: `Get An Estimate`,
    dropdown: false,
    button: true,
    link: "/estimate",
  },
  {
    title: "Login",
    dropdown: false,
    link: "/auth/login",
    icon: ProfileIcon,
  },
];

export function NavbarOptions() {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
  const [isCategory, setIsCategory] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [categories, setCategories] = useState<CategoryModel[]>([]);

  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();
  const dispatch = useDispatch();

  const fetchCategories = async () => {
    setIsLoading(true);
    try {
      const categoriesRes = await CategoryService.Instance.GetCategories();
      setCategories(categoriesRes);
      dispatch(allCategories(categoriesRes));
      setIsLoading(false);
    } catch (err: any) {
      setIsLoading(false);
      toast.error("Error while fetching categories:" + err.message);
    }
  };

  useEffect(() => {
    fetchCategories();
  }, []);

  const noOfCartItems = useAppSelector((state) => state.cart.totalCount);

  if (currentUser?.["MAT-TOKEN"]) {
    options[5] = {
      title: currentUser?.email ?? "",
      dropdown: false,
      isProfile: true,
      link: "/profile/dashboard",
    };
  } else {
    options[4].title =
      noOfCartItems > 0
        ? `Estimate (${noOfCartItems}) Products`
        : "Get An Estimate";
  }

  useEffect(() => {
    const isCategoryUrl = /\/category/.test(location.pathname);
    setIsCategory(isCategoryUrl);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!dropdownRef.current?.contains(event.target as Node)) {
        setIsDropdownOpen(false);
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [dropdownRef]);

  return (
    <div className="navbar-options-root">
      <BusyIndicator loading={isLoading} />
      {options.map((value, index) => {
        if (!value.dropdown && !value.button && !value.isProfile) {
          return (
            <div className="normal-link" key={index}>
              {value.icon && (
                <img
                  src={value.icon}
                  alt="profile"
                  style={{ marginInline: 2 }}
                />
              )}
              <NavLink
                key={index}
                to={value.link}
                style={({ isActive }) => ({
                  textDecoration: "none",
                  color: isActive ? "#000" : "#555353",
                  cursor: "pointer",
                  borderBottom: isActive ? "3px solid #CF6837" : "",
                })}
              >
                {value.title}
              </NavLink>
            </div>
          );
        } else if (value.dropdown) {
          return (
            <div className="dropdown-wrapper" key={index}>
              <div
                className="normal-link"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                style={{ borderBottom: isCategory ? "3px solid #CF6837" : "" }}
              >
                {value.title}
                <div className="dropdown-icon">
                  <img
                    src={DropdownSvg}
                    alt=""
                    style={{ rotate: isDropdownOpen ? "180deg" : "0deg" }}
                  ></img>
                </div>
              </div>
              {isDropdownOpen && (
                <div className="dropdown-options" ref={dropdownRef}>
                  {categories.map((item) => (
                    <NavLink
                      key={item.productCategoryId}
                      to={
                        item.productCategoryName
                          .toLocaleLowerCase()
                          .includes("quartz")
                          ? "category/" + item.productCategoryId
                          : "category/" + item.productCategoryId
                      }
                      onClick={() => setIsDropdownOpen(false)}
                      style={({ isActive }) => ({
                        textDecoration: "none",
                        color: isActive ? "#000" : "",
                        cursor: "pointer",
                        borderBottom: isActive ? "3px solid #CF6837" : "",
                      })}
                    >
                      {item.productCategoryName}
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          );
        } else if (value.button) {
          return (
            <div className="normal-button" key={index}>
              {value.icon && (
                <img
                  src={value.icon}
                  alt="profile"
                  style={{ marginInline: 2 }}
                />
              )}
              <NavLink
                style={({ isActive }) => ({
                  textDecoration: "none",
                  marginInline: 8,
                  color: "#CF6837",
                  borderBottom: isActive ? "3px solid #CF6837" : "",
                })}
                to={value.link}
              >
                {value.title}
              </NavLink>
            </div>
          );
        } else {
          return (
            <div className="normal-button" key={index}>
              {!currentUser?.["MAT-TOKEN"] ? (
                <>
                  <div className="up-arrow">
                    <img src={addIcon} alt=""></img>
                  </div>
                  <NavLink
                    style={({ isActive }) => ({
                      textDecoration: "none",
                      marginInline: 8,
                      color: "#CF6837",
                      borderBottom: isActive ? "3px solid #CF6837" : "",
                    })}
                    to={value.link}
                  >
                    {value.title}
                  </NavLink>
                </>
              ) : (
                <Link to={value.link} className="profile-icon">
                  {value.title.slice(0, 1)}
                </Link>
              )}
            </div>
          );
        }
      })}
    </div>
  );
}

export function NavbarOptionsMobile() {
  const [open, setOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isCategory, setIsCategory] = useState(false);
  const categoriesFormStore = useAppSelector((state) => state.categories);
  const [categories, setCategories] =
    useState<CategoryModel[]>(categoriesFormStore);

  useEffect(() => {
    //console.log("#", categoriesFormStore, categories);
    setCategories(categoriesFormStore);
  }, [categoriesFormStore]);

  const ref = useRef<HTMLDivElement>(null);
  const categoryRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const currentUser = AuthService.getCurrentUser();

  useEffect(() => {
    const isCategoryUrl = /\/category/.test(location.pathname);
    setIsCategory(isCategoryUrl);
  }, [location.pathname]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        !ref.current?.contains(event.target as Node) &&
        !categoryRef.current?.contains(event.target as Node) &&
        !dropdownRef.current?.contains(event.target as Node)
      ) {
        setOpen(false);
        setIsDropdownOpen(false);
      }
      if (
        !ref.current?.contains(event.target as Node) &&
        dropdownRef.current?.contains(event.target as Node)
      ) {
        setOpen(false);
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref, dropdownRef]);

  const handleCategoryClick = () => {
    setIsDropdownOpen((prev) => !prev); // Toggle the dropdown state
  };

  return (
    <div className="navbar-option-root-mobile-wrapper">
      <div className="navbar-options-root-mobile">
        {currentUser?.email && (
          <Link to={options[5].link} className="profile-icon">
            {options[5].title.slice(0, 1)}
          </Link>
        )}
        <div className="ham-icon" ref={ref}>
          <img
            src={HamIcon}
            alt=""
            onClick={() => {
              setOpen((prevOpen) => !prevOpen);
              setIsDropdownOpen(false); // Close the category dropdown when ham-icon is clicked
            }}
          ></img>
        </div>
        {open && (
          <div className="navbar-option-mobile-wrapper">
            <div className="navbar-option-mobile">
              {options.map((value, index) => {
                if (!value.dropdown && !value.button && !value.isProfile) {
                  return (
                    <div className="normal-link-mobile-wrapper" key={index}>
                      <div className="normal-link">
                        {value.icon && (
                          <img
                            src={value.icon}
                            alt="profile"
                            style={{ marginInline: 2 }}
                          />
                        )}
                        <NavLink
                          key={index}
                          to={value.link}
                          style={({ isActive }) => ({
                            textDecoration: "none",
                            color: isActive ? "#000" : "#555353",
                            cursor: "pointer",
                            borderBottom: isActive ? "3px solid #CF6837" : "",
                          })}
                        >
                          {value.title}
                        </NavLink>
                      </div>
                    </div>
                  );
                } else if (value.dropdown) {
                  return (
                    <div
                      className="dropdown-wrapper"
                      key={index}
                      ref={categoryRef}
                    >
                      <div
                        className="normal-link"
                        onClick={handleCategoryClick}
                      >
                        <span
                          style={{
                            color: isCategory ? "black" : "",
                            borderBottom: isCategory ? "3px solid #CF6837" : "",
                          }}
                        >
                          {value.title}
                        </span>
                        <div className="dropdown-icon">
                          <img
                            src={DropdownSvg}
                            alt=""
                            style={{
                              transform: `rotate(${
                                isDropdownOpen ? "180deg" : "0deg"
                              })`,
                            }}
                          ></img>
                        </div>
                      </div>
                      {isDropdownOpen && (
                        <div className="dropdown-options" ref={dropdownRef}>
                          {categories?.map((item) => (
                            <NavLink
                              key={item.productCategoryId}
                              to={
                                item.productCategoryName
                                  .toLocaleLowerCase()
                                  .includes("quartz")
                                  ? "category/" + item.productCategoryId
                                  : "category/" + item.productCategoryId
                              }
                              onClick={() => setIsDropdownOpen(false)}
                              style={({ isActive }) => ({
                                textDecoration: "none",
                                color: isActive ? "#000" : "",
                                cursor: "pointer",
                                borderBottom: isActive
                                  ? "3px solid #CF6837"
                                  : "",
                              })}
                            >
                              {item.productCategoryName}
                            </NavLink>
                          ))}
                        </div>
                      )}
                    </div>
                  );
                } else if (value.button) {
                  return (
                    <div
                      className="normal-button-wrapper-mobile"
                      key={index}
                      style={{ marginTop: 10 }}
                    >
                      <div className="estimate-btn-mobile">
                        <div className="up-arrow">
                          <img src={addIcon} alt=""></img>
                        </div>
                        <NavLink
                          style={({ isActive }) => ({
                            textDecoration: "none",
                            marginInline: 0,
                            color: "#CF6837",
                            borderBottom: isActive ? "3px solid #CF6837" : "",
                            wordWrap: "normal",
                          })}
                          to={value.link}
                        >
                          {value.title}
                        </NavLink>
                      </div>
                    </div>
                  );
                } else {
                  return <></>;
                }
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

const Navbar: React.FC = () => {
  return (
    <div className="navbar-root">
      <Link className="navbar-image" to="/">
        <img src={MatbookLogo} alt=""></img>
      </Link>
      <div className="navbar-options">
        <NavbarOptions></NavbarOptions>
        <NavbarOptionsMobile></NavbarOptionsMobile>
      </div>
    </div>
  );
};

export default Navbar;
