import React, { FC } from "react";
import "./Pagination.css";
import LeftArrow from "../../assets/Catalog Home/Pagination left arrow.svg";
import RightArrow from "../../assets/Catalog Home/Pagination right arrow.svg";

type PaginationProps = {
  rowData: any;
  setRowData: any;
  helperFunction?: any;
};

const Pagination: FC<PaginationProps> = (props) => {
  const itemsPerPage = props.rowData?.products?.pageSize;
  const currentPage = props.rowData?.products?.pageNumber;
  const totalItems = props.rowData?.products?.total;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const handlePageChange = (newPage: number) => {
    props.setRowData({ ...props.rowData, pageNumber: newPage - 1 });
    props.helperFunction(newPage - 1);
  };

  const renderPageNumbers = () => {
    const pages = [];
    if (totalPages <= 5) {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      let startPage, endPage;

      if (currentPage <= 3) {
        startPage = 1;
        endPage = 5 - 1;
      } else if (currentPage >= totalPages - 2) {
        startPage = totalPages - 5 + 2;
        endPage = totalPages;
      } else {
        startPage = currentPage - 1;
        endPage = currentPage + 1;
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (startPage > 2) {
        pages.unshift("...");
      }
      if (endPage < totalPages - 1) {
        pages.push("...");
      }
    }

    return pages.map((page, index) => (
      <button
        className={`page-number ${page === currentPage + 1 ? "active" : ""} `}
        key={index}
        onClick={() => handlePageChange(+page)}
        disabled={page === "..."}
      >
        {page}
      </button>
    ));
  };

  return (
    <div className="pagination">
      {totalPages > 0 && (
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <img src={LeftArrow} alt="" />
        </button>
      )}
      {renderPageNumbers()}
      {totalPages && (
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(props.rowData.totalItems / itemsPerPage)
          }
        >
          <img src={RightArrow} alt="" />
        </button>
      )}
    </div>
  );
};

export default Pagination;
