import React, { Children, FC } from "react";
import "./Tooltip.css";

interface TooltipProps {
  children?: string | JSX.Element | JSX.Element[];
  title: string;
  position?: "top" | "right" | "bottom" | "left";
}

const Tooltip: FC<TooltipProps> = (props) => {
  return (
    <div className="tooltip">
      <span className="tooltiptext">{props.title}</span>
      {props.children}
    </div>
  );
};
export default Tooltip;
