import { PayloadAction, createSlice } from "@reduxjs/toolkit";

//Create initial state
const initialState: { tempPassword: string } = {
  tempPassword: "",
};

export const OnboarsingSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    holdPassword: (state, action: PayloadAction<{ tempPassword: string }>) => {
      state.tempPassword = action.payload.tempPassword;
    },
    clearPassword: (state) => {
      state.tempPassword = "";
    },
  },
});

export default OnboarsingSlice.reducer;
export const { holdPassword, clearPassword } = OnboarsingSlice.actions;
