import React, { useEffect, useState } from "react";
import { isUndefined } from "lodash";
import "./MessageAlert.css"; // Import your CSS file
import { MessageAlertModel } from "../../services/Models/Common.models";

interface MessageAlertProps {
  message?: MessageAlertModel;
}

export const MessageAlert: React.FC<MessageAlertProps> = (props) => {
  const [messageAlert, setMessageAlert] = useState<
    MessageAlertModel | undefined
  >(props.message);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (!isUndefined(props.message)) {
      setMessageAlert(props.message);
      setIsVisible(true);

      // Hide the alert after 3 seconds
      const timeoutId = setTimeout(() => {
        setIsVisible(false);
      }, 3000);

      // Clear the timeout when the component unmounts or the message changes
      return () => clearTimeout(timeoutId);
    }
  }, [props.message]);

  const handleClose = () => {
    setIsVisible(false);
  };

  return (
    <div className={`message-alert ${isVisible ? "slide-down" : ""}`}>
      <div className={`alert ${messageAlert?.status}`} onClick={handleClose}>
        {messageAlert?.message}
      </div>
    </div>
  );
};
