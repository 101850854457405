import React, { FC } from "react";
import "./Comps.css";
import { OnboardingModel } from "../auth.models";

interface Comp2Props {
  rowData: OnboardingModel;
  setRowData: React.Dispatch<React.SetStateAction<OnboardingModel>>;
}

const Comp2: FC<Comp2Props> = (props) => {
  return (
    <div className="comp-wraper">
      <h1>How Can We Contact You ?</h1>
      <div className="address-wraper">
        <div>
          <label htmlFor="email">Email </label>
          <input
            type="email"
            id="email"
            value={props.rowData.email ?? ""}
            onChange={(e) => {
              props.setRowData({ ...props.rowData, email: e.target.value });
            }}
            placeholder="example@gmail.com"
            required
            readOnly={true}
          />
        </div>
        <div>
          <label htmlFor="phone">Phone number </label>
          <input
            type="tel"
            id="phone"
            value={props.rowData.phoneNumber}
            onChange={(e) => {
              props.setRowData({
                ...props.rowData,
                phoneNumber: +e.target.value,
              });
            }}
            placeholder="+1 217 721 9563"
            required
          />
        </div>
        <div>
          <label htmlFor="address">Company Address</label>
          <input
            type="text"
            id="address"
            value={props.rowData.locality ?? ""}
            onChange={(e) => {
              props.setRowData({ ...props.rowData, locality: e.target.value });
            }}
            placeholder="Work Address"
          />
        </div>
        <div>
          <label htmlFor="street">Street</label>
          <input
            type="text"
            id="street"
            value={props.rowData.street}
            onChange={(e) => {
              props.setRowData({ ...props.rowData, street: e.target.value });
            }}
            placeholder="36th Ave SW"
          />
        </div>
        <div>
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            value={props.rowData.city}
            onChange={(e) => {
              props.setRowData({ ...props.rowData, city: e.target.value });
            }}
            placeholder="Norman"
          />
        </div>
        <div>
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            value={props.rowData.state}
            onChange={(e) => {
              props.setRowData({ ...props.rowData, state: e.target.value });
            }}
            placeholder="OK"
          />
        </div>
        <div>
          <label htmlFor="zipcode">Zipcode</label>
          <input
            type="num"
            id="zipcode"
            value={props.rowData.zipcode}
            onChange={(e) => {
              props.setRowData({ ...props.rowData, zipcode: +e.target.value });
            }}
            placeholder="73072"
          />
        </div>
      </div>
    </div>
  );
};
export default Comp2;
