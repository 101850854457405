export const HTTP_METHODS = {
  GET: "GET",
  POST: "POST",
};

export const COMMON_HEADERS: any = {
  "Content-Type": "application/json",
};

export const LOCAL_STORAGE_KEYS = {
  USER_ACCOUNT_KEY: "UserAccount",
  LOOK_UP_KEY: "LookUp",
};

export const ORDER_STATUS = {
  ORDER_RECIVED: "Order Received",
  MANUFACTURED_AND_SHIPPED: "Manufactured and Shipped",
  IN_TRANSIST: "In Transit",
  DELIVERD: "Deliverd",
};
