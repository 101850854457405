import { APIConfirmMessage } from "../services/Common/api-response.model";
import {
  HTTP_METHODS,
  LOCAL_STORAGE_KEYS,
} from "../services/Common/common.constants";
import { CommonService } from "../services/Common/common.service";
import { CommonUtils } from "../services/Common/common.utils";
import {
  UserLoginModel,
  UserLoginResponseModel,
  OnboardingModel,
} from "../auth/auth.models";
import { isUndefined } from "lodash";
import { googleLogout } from "@react-oauth/google";

export default class AuthService {
  private _baseUrl = process.env.REACT_APP_BASE_API_URL;
  private static _instance: AuthService;

  public static get Instance(): AuthService {
    return this._instance || (this._instance = new this());
  }

  public async login(payload: UserLoginModel): Promise<UserLoginResponseModel> {
    let result!: UserLoginResponseModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, {
      user: payload.userName,
      password: payload.password,
    });
    await fetch(`${this._baseUrl}login/email`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: UserLoginResponseModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<UserLoginResponseModel>(
            LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY,
            { ...response, email: response.email ?? payload.userName }
          );
        }
        result = response;
      });
    return result;
  }

  public async googleOathResponse(
    payload: string
  ): Promise<UserLoginResponseModel> {
    let result!: UserLoginResponseModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this._baseUrl}login/oauth2/code/google`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: UserLoginResponseModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<UserLoginResponseModel>(
            LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY,
            response
          );
        }
        result = response;
      });
    return result;
  }

  public logout() {
    googleLogout();
    CommonUtils.removeItem(LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY);
  }

  public async generateOtp(emailId: string): Promise<APIConfirmMessage> {
    let result!: APIConfirmMessage;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(`${this._baseUrl}login/generateOtp/${emailId}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: APIConfirmMessage) => {
        result = response;
      });
    return result;
  }

  public async submitOtp(
    emailId: string,
    otp: string
  ): Promise<UserLoginResponseModel> {
    let result!: UserLoginResponseModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(
      `${this._baseUrl}login/verifyOtp/${emailId}?otp=${otp}`,
      requestInfo
    )
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: UserLoginResponseModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<UserLoginResponseModel>(
            LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY,
            response
          );
        }
        result = response;
      });
    return result;
  }

  public async updatePassword(
    emailId: string,
    password: string
  ): Promise<UserLoginResponseModel> {
    let result!: UserLoginResponseModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST);
    await fetch(
      `${this._baseUrl}user/updatePassword/${emailId}?password=${password}`,
      requestInfo
    )
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: UserLoginResponseModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<UserLoginResponseModel>(
            LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY,
            response
          );
        }
        result = response;
      });
    return result;
  }

  public async userOnboarding(
    payload: OnboardingModel
  ): Promise<UserLoginResponseModel> {
    let result!: UserLoginResponseModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this._baseUrl}user/SignUp`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: UserLoginResponseModel) => {
        if (!isUndefined(response)) {
          CommonUtils.setItem<UserLoginResponseModel>(
            LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY,
            response
          );
        }
        result = response;
      });
    return result;
  }

  public static getCurrentUser() {
    const userStr = CommonUtils.getItem<UserLoginResponseModel>(
      LOCAL_STORAGE_KEYS.USER_ACCOUNT_KEY
    );
    return userStr;
  }
}
