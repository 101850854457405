import React, { lazy, Suspense, useCallback, useEffect, useState } from "react";
import "./Home.css";
import { BusyIndicator } from "../HelperComps/BusyIndicator";
import { CategoryModel } from "../../services/Models/Categories.model";
import { CategoryService } from "../../services/API/category.service";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { allCategories } from "../../store/features/CategorySlice";
import { useAppSelector } from "../../store/Store";

const Hero = lazy(() => import("./Hero/Hero"));
const Categories = lazy(() => import("./Categories/Categories"));
const HowWeWork = lazy(() => import("./HowWeWork/HowWeWork"));
const SubHeroText = lazy(() => import("./SubHeroText/SubHeroText"));
const WhatsDifferent = lazy(() => import("./WhatsDifferent/WhatsDifferent"));
const Perfection = lazy(() => import("./Perfection/Perfection"));

const Home = () => {
  const categoriesFromStore = useAppSelector((state) => state.categories);
  const [categories, setCategories] =
    useState<CategoryModel[]>(categoriesFromStore);

  useEffect(() => {
    //console.log("#toast", categories, categoriesFromStore);
    setCategories(categoriesFromStore);
  }, [categoriesFromStore]);

  return (
    <div className="home">
      <div className="home-wrapper">
        <Suspense fallback={<BusyIndicator loading={true} />}>
          <Hero />
          <SubHeroText />
          <Categories categories={categories} />
          <HowWeWork />
          <WhatsDifferent />
          <Perfection />
        </Suspense>
      </div>
    </div>
  );
};

export default Home;
