import AuthService from "./auth.service";

export default function authHeader() {
  const userAccount = AuthService.getCurrentUser();
  let user = null;
  if (userAccount?.["MAT-TOKEN"]) user = userAccount;

  if (user && user["MAT-TOKEN"]) {
    return {
      Authorization: "Bearer " + user["MAT-TOKEN"],
      "MAT-TOKEN": user["MAT-TOKEN"],
    }; // for Spring Boot back-end
    // return { 'x-access-token': user.accessToken };       // for Node.js Express back-end
  } else {
    return { Authorization: "" }; // for Spring Boot back-end
    // return { 'x-access-token': null }; // for Node Express back-end
  }
}
