import React, {
  ComponentState,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import Cards from "./Cards/Cards";
import "./index.css";
// import CoverImage from "../../assets/aboutUs/About Us Page Hero.jpg";
import OurMission from "../../assets/aboutUs/Frame 1000001909.svg";
import OurVision from "../../assets/aboutUs/Frame 1000001902 copy.svg";
import { BusyIndicator } from "../HelperComps/BusyIndicator";

const summaryList = [
  {
    title: "Our Mission",
    summary: [
      `Empowering Global Construction`,
      `Our mission is to simplify and enhance cross-border trade in building materials through a `,
      `technology-first approach, fostering sustainability and excellence.`,
      `We are committed to providing innovative solutions that leverage cutting-edge technology, connecting suppliers, 
    manufacturers, and customers to make quality products accessible worldwide while respecting cultural values and environmental responsibility.`,
    ],
  },
  {
    title: "Our Vision",
    summary: [
      `At MatBook, we envision a future where trading building materials across borders is simple, sustainable, and transformative.`,
      `We aim to revolutionize the construction industry by creating an innovative platform that makes cross-border trade efficient and environmentally responsible.
    With a focus on technology, sustainability, and customer satisfaction, we'll build strong global partnerships to connect suppliers, manufacturers, and customers seamlessly.
    Our dedication to quality and cultural understanding will be the keys to our success.
    Together, we're driving change in the building materials industry and making cross-border trade accessible and eco-friendly for all.`,
    ],
  },
  {
    title: "Our Values",
    summary: [
      `At MatBook, we envision a future where trading building materials across borders is simple, sustainable, and transformative. 
    We aim to revolutionize the construction industry by creating an innovative platform that makes cross-border trade efficient and environmentally responsible.
    With a focus on technology, sustainability, and customer satisfaction, we'll build strong global partnerships to connect suppliers, manufacturers, and customers seamlessly.
    Our dedication to quality and cultural understanding will be the keys to our success.
    Together, we're driving change in the building materials industry and making cross-border trade accessible and eco-friendly for all.`,
    ],
  },
];

export default function AboutUs() {
  const [state, setState] = useState<ComponentState>({
    isLoading: false,
  });
  const [words, setWords] = useState<string[]>([]);
  const subHeroTextRef = useRef(null);

  useMemo(() => {
    const text =
      "... are shaking up construction logistics. Our smart tech helps you track your delivery, improving timeline estimations. Our Quality Control centre ensures top-notch standards, giving you confidence in every delivery.";
    const wordArray = text.split(" ");

    setWords(wordArray);
  }, []);

  const [dimensions, setDimensions] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };
  React.useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.25,
    };

    const handleIntersection: IntersectionObserverCallback = (
      entries,
      observer
    ) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-class");
          observer.unobserve(entry.target);
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);

    if (subHeroTextRef.current) {
      observer.observe(subHeroTextRef.current);
    }

    return () => {
      if (subHeroTextRef.current) {
        observer.unobserve(subHeroTextRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const imagesToLoad = [
      `${process.env.REACT_APP_S3_BASE_URL}/MatBookImages/About%20Us%20Page%20Hero.jpg`,
      `${process.env.REACT_APP_S3_BASE_URL}/MatBookImages/About%20Us%20Page%20Hero.jpg`,
      OurMission,
      OurVision,
    ];

    const loadImage = (src: string) => {
      return new Promise((resolve) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
      });
    };

    const loadImages = async () => {
      await Promise.all(imagesToLoad.map(loadImage));
      setState({ isLoading: false });
    };

    loadImages();
  }, []);

  if (state.isLoading) {
    return (
      <div className="category-item-list-root">
        <BusyIndicator loading={state.isLoading} />
      </div>
    );
  }

  return (
    <div className="about-us-root">
      {state.isLoading && (
        <div className="category-item-list-root">
          <BusyIndicator loading={state.isLoading} />
        </div>
      )}
      <div className="about-us-top-nav-wrapper">
        <div className="about-us-top-nav">
          <div>
            <img
              className="image-wrapper skeleton-img-loader"
              onLoad={() => setState({ isLoading: false })}
              src={`${process.env.REACT_APP_S3_BASE_URL}/MatBookImages/About%20Us%20Page%20Hero.jpg`}
              alt=""
              loading={"lazy"}
            />
            <div className="about-us-title">
              We At <span style={{ color: "#CF6837" }}>Matbook...</span>
            </div>
          </div>
          <div className="about-us-summary" ref={subHeroTextRef}>
            {words.map((word, index) => (
              <span
                key={index}
                className={
                  word.toUpperCase() === "SHAKING"
                    ? "shaking"
                    : "about-us-summary-text"
                }
                style={{ animationDelay: `${index * 0.1}s` }}
              >
                {word}&nbsp;
              </span>
            ))}
          </div>
        </div>
      </div>
      <div className="card-wrapper-section">
        <div className="about-us-card-wrpper">
          <Cards
            icon={OurMission}
            type="primary"
            title={summaryList[0].title}
            summary={summaryList[0].summary}
          />
        </div>
        <div className="about-us-card-wrpper about-us-non-init-comps">
          <Cards
            icon={OurVision}
            type="secondary"
            title={summaryList[1].title}
            summary={summaryList[1].summary}
          />
        </div>
        <div className="about-us-card-wrpper about-us-non-init-comps">
          <Cards
            type="other"
            title={summaryList[2].title}
            summary={summaryList[0].summary}
          />
        </div>
      </div>
    </div>
  );
}
