import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CategoryModel } from "../../services/Models/Categories.model";

// Function to create initial state, considering local storage
const getInitialState = (): CategoryModel[] => {
  const storedCategories = localStorage.getItem("categories");
  return storedCategories ? JSON.parse(storedCategories) : [];
};

const initialState: CategoryModel[] = getInitialState();

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    allCategories: (state, action: PayloadAction<CategoryModel[]>) => {
      if (action.payload.length > 0) {
        state.splice(0, state.length, ...action.payload);
        //console.log("#store", state, action.payload);

        // Save cart state to local storage after modification
        localStorage.setItem("categories", JSON.stringify(state));
      }
    },
  },
});

export default categoriesSlice.reducer;
export const { allCategories } = categoriesSlice.actions;
