import React from "react";
import "./App.css";
import Navbar from "./sections/Navbar/Navbar";
import Home from "./sections/Home Page/Home";
import Contact from "./sections/Contact Page/Contact";
import Footer from "./sections/Footer/Footer";
import AboutUs from "./sections/aboutus/index";
import CategoryItemsList from "./sections/Catalog/CategoryItemsList";
import CategoryItemDetail from "./sections/Catalog/ItemDetail";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import CatalogHomeContent from "./sections/Catalog/CatalogHomeContent";
import Estimator from "./sections/Profile/Estimates/Estimator";
import ScrollToTop from "./sections/HelperComps/ScrollToTop";
import LoginPage from "./auth/LoginPage";
import SignupPage from "./auth/SignupPage";
import Onboarding from "./auth/Onboarding";
import Missing from "./sections/HelperComps/Missing";
import Dashboard from "./sections/Profile/Dashboard";
import EstimatesList from "./sections/Profile/Estimates/EstimatesList";
import EstimatesItemDetail from "./sections/Profile/Estimates/EstimatesItemDetail";
import OrdersList from "./sections/Profile/Orders/OrderList";
import OrdersItemDetail from "./sections/Profile/Orders/OrdersItemDetail";
import { ToastContainer } from "react-toastify";

// Separate component for routes without headers and footers
const AuthRoutes = () => (
  <Routes>
    <Route path="login" element={<LoginPage />} />
    <Route path="signup" element={<SignupPage />} />
    <Route path="onboarding" element={<Onboarding />} />
    <Route path="*" element={<Missing />} />
  </Routes>
);

function App() {
  return (
    <div className="app">
      <ToastContainer stacked={true} />
      <Router>
        <Routes>
          {/* Auth routes without headers and footers */}
          <Route path="/auth/*" element={<AuthRoutes />} />

          {/* Main routes with headers and footers */}
          <Route
            path="/*"
            element={
              <>
                <ScrollToTop />
                <Navbar />
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="aboutus" element={<AboutUs />} />
                  <Route path="estimate" element={<Estimator />} />
                  <Route path="catalog" element={<CatalogHomeContent />} />
                  <Route path="category">
                    <Route index element={<CatalogHomeContent />} />
                    <Route
                      path=":productCategoryId"
                      element={<CategoryItemsList />}
                    />
                    <Route
                      path="item/:productId"
                      element={<CategoryItemDetail />}
                    />
                  </Route>
                  <Route path="profile">
                    <Route index path="dashboard" element={<Dashboard />} />
                    <Route path="estimates" element={<EstimatesList />} />
                    <Route
                      path="estimates/:estimationId"
                      element={<EstimatesItemDetail />}
                    />
                    <Route path="orders" element={<OrdersList />} />
                    <Route
                      path="orders/:orderId"
                      element={<OrdersItemDetail />}
                    />
                  </Route>
                  <Route path="profile" element={<Estimator />} />
                  <Route path="*" element={<Missing />} />
                </Routes>

                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
