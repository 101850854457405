import React, { FC, useState } from "react";
import "./index.css";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/Footer/Matbook Logo.svg";
import Eye from "../assets/Auth/eye.svg";
import GoogleIcon from "../assets/Auth/Google_logo.svg";
import { BusyIndicator } from "../sections/HelperComps/BusyIndicator";
import { GoogleOauthResponseModel, UserSignupModel } from "./auth.models";
import { toast } from "react-toastify";
import AuthService from "./auth.service";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

interface SignupPageProps {}

const SignupPage: FC<SignupPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<UserSignupModel>(
    {} as UserSignupModel
  );
  const [showPassword1, setShowPassword1] = useState<boolean>(false);
  const [showPassword2, setShowPassword2] = useState<boolean>(false);

  const navigate = useNavigate();
  const authService = AuthService.Instance;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isValidEmail(rowData.userName)) {
      setIsLoading(false);
      toast.warning("Please enter a valid email or phone number");
      return false;
    } else if (rowData.password !== rowData.confirmPassword) {
      //check if confirmPassword = password
      setIsLoading(false);
      toast.warning("Confirm Password is not maching with the password!");
      return false;
    } else {
      await authService //send the payload to the backend
        .login(rowData)
        .then((res) => {
          setIsLoading(false);
          navigate(res.redirectUrl);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    }
  };

  const isValidEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  // const isValidPhoneNumber = (value: string): boolean => {
  //   const phoneRegex = /^\d{10}$/;
  //   return phoneRegex.test(value);
  // };

  const handleGoogleLogin = async (
    credentialResponse: GoogleOauthResponseModel
  ) => {
    setIsLoading(true);
    await authService
      .googleOathResponse(credentialResponse.credential ?? "")
      .then((res) => {
        setIsLoading(false);
        navigate(res.redirectUrl);
        // console.log("#", res.redirectionUrl);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  // const handleAppleIDLogin = () => {
  // Handle Apple ID login logic here
  // };

  return (
    <div className="login-root-container">
      <BusyIndicator loading={isLoading} />
      <div className="login-container">
        <img
          src={Logo}
          alt="Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
        <h2>
          Start Your Journey With <span>Matbook</span>
        </h2>
        <p>Your Global Construction Material Supplier</p>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-row">
            <label>Email </label>
            <input
              type="text"
              placeholder="Enter your email address"
              value={rowData.userName}
              onChange={(e) =>
                setRowData({ ...rowData, userName: e.target.value })
              }
              required
            />
          </div>
          <div className="form-row">
            <label>Password</label>
            <input
              type={showPassword1 ? "text" : "password"}
              placeholder="Enter your password"
              value={rowData.password}
              onChange={(e) =>
                setRowData({ ...rowData, password: e.target.value })
              }
              required
            />
            <img
              src={Eye}
              alt=""
              onClick={() => setShowPassword1((hide) => !hide)}
            />
          </div>
          <div className="form-row">
            <label>Confirm Password</label>
            <input
              type={showPassword2 ? "text" : "password"}
              placeholder="Enter your password again"
              value={rowData.confirmPassword}
              onChange={(e) =>
                setRowData({ ...rowData, confirmPassword: e.target.value })
              }
              required
            />
            <img
              src={Eye}
              alt=""
              onClick={() => setShowPassword2((hide) => !hide)}
            />
          </div>
          <button type="submit" className="submit-btn">
            Sign Up
          </button>
        </form>

        <div className="social-login">
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? ""}
          >
            <GoogleLogin
              onSuccess={(credentialResponse) =>
                handleGoogleLogin(credentialResponse)
              }
              onError={() => {
                setIsLoading(false);
                toast.error("Some error occurred. Please try after sometime");
              }}
              containerProps={{
                style: {
                  width: 300,
                  maxWidth: "100%",
                  height: "100%",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
              theme="outline"
              type="standard"
              width={"100%"}
              size="large"
              useOneTap
              ux_mode="popup"
              text="signup_with"
              context="signup"
            />
          </GoogleOAuthProvider>
          {/* <button onClick={handleGoogleLogin}>
            <img src={GoogleIcon} alt="" />
            Login with Google
          </button> */}
        </div>
        <p>
          Already A Member?{" "}
          <Link to="/auth/login" style={{ textDecoration: "none" }}>
            <span style={{ color: "#32919D" }}>Login</span>
          </Link>
        </p>
      </div>
    </div>
  );
};

export default SignupPage;
