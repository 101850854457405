import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CartItemModel } from "../../services/Models/Categories.model";

interface Cart {
  totalCount: number;
  items: CartItemModel[];
}

// Function to create initial state, considering local storage
const getInitialState = (): Cart => {
  const storedCart = localStorage.getItem("cart");
  return storedCart ? JSON.parse(storedCart) : { totalCount: 0, items: [] };
};

const initialState: Cart = getInitialState();

export const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addToCart: (state, action: PayloadAction<{ item: CartItemModel }>) => {
      state.totalCount++;
      state.items.push(action.payload.item);
      // Save cart state to local storage after modification
      localStorage.setItem("cart", JSON.stringify(state));
    },

    removeFromCart: (state, action: PayloadAction<{ productId: number }>) => {
      const index = state.items.findIndex(
        (item) => item.productId === action.payload.productId
      );

      if (index !== -1) {
        state.totalCount--;
        state.items.splice(index, 1);
        // Save cart state to local storage after modification
        localStorage.setItem("cart", JSON.stringify(state));
      }
    },

    clearAll: (state) => {
      state.totalCount = 0;
      state.items = [];
      // Clear cart state from local storage
      localStorage.removeItem("cart");
    },
  },
});

export default cartSlice.reducer;
export const { addToCart, removeFromCart, clearAll } = cartSlice.actions;
