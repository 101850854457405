import React, { FC, useEffect, useState } from "react";
import "./Dashboard.css";
import UpRightArrow from "../../assets/Profile/UpRightArrow.svg";
import SaveIcon from "../../assets/Profile/Save.svg";
import LogoutIcon from "../../assets/Profile/logout.svg";
import { OnboardingModel } from "../../auth/auth.models";
import { Link, useNavigate } from "react-router-dom";
import { BusyIndicator } from "../HelperComps/BusyIndicator";
import { CommonUtils } from "../../services/Common/common.utils";
import AuthService from "../../auth/auth.service";
import { toast } from "react-toastify";
import { ProfileService } from "../../services/API/profile.service";

type DashboardProps = {};

const Dashboard: FC<DashboardProps> = (props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<OnboardingModel>(
    {} as OnboardingModel
  );
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();

  const authService = AuthService.Instance;
  const profileService = ProfileService.Instance;

  useEffect(() => {
    const loadData = async () => {
      setIsLoading(true);
      if (!currentUser?.["MAT-TOKEN"]) {
        AuthService.Instance.logout();
        setTimeout(() => {
          toast.error("Something went wrong, please login again");
          setIsLoading(false);
          navigate("/auth/login");
        }, 1000);
        return;
      }
      await profileService
        .getUserDetail(currentUser.email ?? "")
        .then((res) => {
          setIsLoading(false);
          setRowData(res);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    };
    loadData();
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setRowData({ ...rowData, [e.target.name]: e.target.value });
  };

  const handleSaveChanges = async () => {
    setIsLoading(true);

    await profileService
      .updateUserDetail(rowData)
      .then((res) => {
        setIsLoading(false);
        toast.success("Profile updated successfully!");
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const handleLogout = () => {
    setIsLoading(true);
    authService.logout();
    // Simulate an asynchronous operation (e.g., API call)
    toast.success("Logout successful!");
    setTimeout(() => {
      setIsLoading(false);
      // navigate("/auth/login");
      window.location.href = window.location.origin + "/auth/login";
    }, 500);
  };

  return (
    <div className="dashboard-root">
      <BusyIndicator loading={isLoading} />
      <div className="dashboard-wraper">
        <h2>Your Account</h2>
        {/* <div className="dashboard-links"> */}
        {/* <Link to={"/profile/orders"}>
            Track Your Recent Order <img src={UpRightArrow} alt="" />
          </Link> */}
        {/*<Link to={"/profile/estimates"}>
            Your Estimates <img src={UpRightArrow} alt="" />
          </Link>*/}
        {/* <Link to={"/profile/orders"}>
            Your Orders <img src={UpRightArrow} alt="" />
          </Link> */}
        {/* </div> */}
        <div className="dashboard-detail">
          <h3>Personal Information</h3>
          <div>
            <label htmlFor="name">Full Name</label>
            <input
              type="text"
              id="name"
              name="name"
              placeholder="Name"
              value={rowData.name}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={rowData.email}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="phoneNumber">Phone Number</label>
            <input
              type="number"
              id="phoneNumber"
              name="phoneNumber"
              value={rowData.phoneNumber}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="company">Company Name</label>
            <input
              type="text"
              id="company"
              name="companyName"
              value={rowData.companyName}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="position">Position</label>
            <input
              type="text"
              id="position"
              name="role"
              value={rowData.role}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="dashboard-detail">
          <h3>Address</h3>
          <div>
            <label htmlFor="locality">Locality</label>
            <input
              type="text"
              id="locality"
              placeholder="Home Address"
              name="locality"
              value={rowData.locality}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="street">Street</label>
            <input
              type="text"
              id="street"
              name="street"
              value={rowData.street}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="city">City</label>
            <input
              type="text"
              id="city"
              name="city"
              value={rowData.city}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="state">State</label>
            <input
              type="text"
              id="state"
              name="state"
              value={rowData.state}
              onChange={handleChange}
            />
          </div>
          <div>
            <label htmlFor="zipcode">Zipcode</label>
            <input
              type="number"
              id="zipcode"
              name="zipcode"
              value={rowData.zipcode}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="dashboard-footer">
          <button onClick={handleSaveChanges} disabled={isLoading}>
            <img src={SaveIcon} alt="" />
            Save Changes
          </button>
          <button onClick={handleLogout} disabled={isLoading}>
            <img src={LogoutIcon} alt="" />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
