import { APIConfirmMessage } from "../Common/api-response.model";
import { HTTP_METHODS } from "../Common/common.constants";
import { CommonService } from "../Common/common.service";
import { ContactModel } from "../Models/Common.models";

export class ContactService {
  private _baseUrl = process.env.REACT_APP_BASE_API_URL;
  private serviceUrl = `${this._baseUrl}/form`;
  private static _instance: ContactService;

  public static get Instance(): ContactService {
    return this._instance || (this._instance = new this());
  }

  async SendContactRequest(payload: ContactModel): Promise<APIConfirmMessage> {
    let result!: APIConfirmMessage;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this.serviceUrl}/contactUs`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }
}
