import React from "react";
import "./BusyIndicator.css";

interface BusyIndicatorProps {
  loading: boolean;
}

export const BusyIndicator = (props: BusyIndicatorProps) => {
  return (
    props.loading && (
      <div className="loader-background">
        <div className="loader"></div>
      </div>
    )
  );
};
