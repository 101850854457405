import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { cartSlice } from "./features/CartSlice";
import { categoriesSlice } from "./features/CategorySlice";
import { OnboarsingSlice } from "./features/OnboardingSlice";

export const store = configureStore({
  reducer: {
    cart: cartSlice.reducer,
    categories: categoriesSlice.reducer,
    onboarsing: OnboarsingSlice.reducer,
  },
});

export const useAppDispacth: () => typeof store.dispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<
  ReturnType<typeof store.getState>
> = useSelector;
