import { FC, useEffect, useRef, useState } from "react";
import "./TrackingStepper.css";
import { CommonUtils } from "../../../services/Common/common.utils";

interface TrackingStepperProps {
  stepsConfig: {
    name: string;
    date: string;
    Component?: React.FC;
  }[];
}

const TrackingStepper: FC<TrackingStepperProps> = (props) => {
  const [currentStep, setCurrentStep] = useState(3);
  const [isComplete, setIsComplete] = useState(false);
  const [margins, setMargins] = useState<{
    marginLeft: number;
    marginRight: number;
  }>({
    marginLeft: 0,
    marginRight: 0,
  });
  const stepRef = useRef<HTMLDivElement[] | null[]>([]);

  useEffect(() => {
    if (stepRef.current[0] && stepRef.current[props.stepsConfig.length - 1]) {
      const leftMargin = stepRef.current[0];
      const rightMargin = stepRef.current[props.stepsConfig.length - 1];

      if (leftMargin && rightMargin) {
        setMargins({
          marginLeft: leftMargin.offsetWidth / 2,
          marginRight: rightMargin.offsetWidth / 2,
        });
      }
    }
  }, [stepRef, props.stepsConfig.length]);

  if (!props.stepsConfig.length) {
    return <></>;
  }

  const handleNext = () => {
    setCurrentStep((prevStep) => {
      if (prevStep === props.stepsConfig.length) {
        setIsComplete(true);
        return prevStep;
      } else {
        return prevStep + 1;
      }
    });
  };

  const calculateProgressBarWidth = () => {
    return ((currentStep - 1) / (props.stepsConfig.length - 1)) * 100;
  };

  const ActiveComponent: FC<{}> | undefined =
    props.stepsConfig[currentStep - 1]?.Component;

  return (
    <>
      <div className="stepper">
        {props.stepsConfig.map((step, index) => {
          return (
            <div
              key={step.name}
              ref={(el) => (stepRef.current[index] = el)}
              className={`step ${
                currentStep > index + 1 || isComplete ? "complete" : ""
              } ${currentStep === index + 1 ? "active" : ""} `}
            >
              <div className="step-number">
                {currentStep > index + 1 || isComplete ? (
                  <span>&#10003;</span>
                ) : (
                  ""
                )}
              </div>
              <div className="step-date">
                {CommonUtils.FormatDate(step.date)}
              </div>
              <div className="step-name">{step.name}</div>
            </div>
          );
        })}

        <div
          className="progress-bar"
          style={{
            width: `calc(100% - ${
              margins.marginLeft + margins.marginRight + 10
            }px)`,
            marginLeft: margins.marginLeft,
            marginRight: margins.marginRight,
          }}
        >
          <div
            className="progress"
            style={{ width: `${calculateProgressBarWidth()}%` }}
          ></div>
        </div>
      </div>

      {/* {ActiveComponent && <ActiveComponent />} */}

      {/* {!isComplete && (
        <button className="btn" onClick={handleNext}>
          {currentStep === props.stepsConfig.length ? "Finish" : "Next"}
        </button>
      )} */}
    </>
  );
};

export default TrackingStepper;
