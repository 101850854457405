import React, { FC } from "react";
import { EstimatesListItemModel } from "../../../services/Models/Estimator.models";
import "./EstimatesListItem.css";
import RightArrow from "../../../assets/Right Arrow.svg";
import { Link, useNavigate } from "react-router-dom";

type EstimatesListItemProps = {
  item: EstimatesListItemModel;
  sNo: number;
};

const EstimatesListItem: FC<EstimatesListItemProps> = (props) => {
  const navigate = useNavigate();
  return (
    <div
      className="estimates-list-item"
      onClick={() => navigate(`/profile/estimates/${props.item.estimateId}`)}
    >
      <div>
        <span>S.NO</span>
        <h2>{props.sNo}</h2>
      </div>
      <div className="estimate-thumbnails">
        {props.item.imgUrls.map((img, index) => {
          if (index < 4)
            return (
              <img
                key={index}
                src={img}
                alt=""
                className="skeleton-img-loader"
                loading={"lazy"}
              />
            );
          if (index === 4)
            return <div key={index}>+{props.item.imgUrls.length - 4}</div>;
          else return "";
        })}
      </div>
      <div>
        <span>ID : {props.item.estimateId}</span>
        <h2>{props.item.name}</h2>
      </div>
      <div>
        <span>Date</span>
        <h3>{props.item.dateCreated}</h3>
      </div>
      {/* <div></div> */}
      <div>
        <span>Status</span>
        <h3
          style={{
            color:
              props.item.estimationStatus === "Complete"
                ? "#009D3F"
                : "#ED4337",
          }}
        >
          {props.item.estimationStatus}
        </h3>
      </div>
      {/* <div></div> */}
      <Link to={`/profile/estimates/${props.item.estimateId}`}>
        View <img src={RightArrow} alt="" />
      </Link>
    </div>
  );
};

export default EstimatesListItem;
