import React, { ComponentState, FC, useEffect, useState } from "react";
import "./index.css";
import {
  CategoryItemListModel,
  CategoryModel,
} from "../../services/Models/Categories.model";
import Categories from "./Components/Categories";
import CotegoryItemList from "./Components/ItemList";
import { BusyIndicator } from "../HelperComps/BusyIndicator";
import { CategoryService } from "../../services/API/category.service";
import { MessageAlert } from "../HelperComps/MessageAlert";
import { CommonService } from "../../services/Common/common.service";
import { toast } from "react-toastify";

interface CatalogProps {}

const CatalogHomeContent: FC<CatalogProps> = (props) => {
  const [state = { isLoading: false }, setState] = useState<ComponentState>();
  const [categories, setCategories] = useState<CategoryModel[]>([]);
  const [CategoryItemList, setCategoryItemList] = useState<
    CategoryItemListModel[]
  >([]);

  const categoryService = CategoryService.Instance;

  useEffect(() => {
    const fetchCategories = async () => {
      setState({ isLoading: true });
      try {
        const categories = await categoryService.GetCategories();
        setCategories(categories);

        const quartzCategoryId = categories.find(
          (f) => f.productCategoryName.toLocaleLowerCase() === "quartz"
        )?.productCategoryId;
        const categoryItems = await Promise.all([
          categoryService.GetFeaturedProducts({
            pageSize: 10,
            pageNo: 0,
          }),
          categoryService.GetCategoryProducts({
            productCategoryId: quartzCategoryId,
            pageSize: 10,
            pageNo: 0,
          }),
        ]);

        setCategoryItemList([
          {
            productCategoryId: 0,
            productCategoryName: "Featured Products",
            products: categoryItems[0],
          },
          categoryItems[1],
        ]);
        setState({ isLoading: false });
      } catch (err: any) {
        setState({
          isLoading: false,
        });
        toast.error(err.message);
      } finally {
        setState({ isLoading: false });
      }
    };

    fetchCategories();
  }, []);

  return (
    <div className="catalog-home-root">
      <BusyIndicator loading={state?.isLoading} />
      <MessageAlert message={state.messageAlert} />
      <div className="catalog-title-wraper">
        <h1>Product Catalog</h1>
        <p>
          Select from our expansive collection of materials and request a cost
          estimate
        </p>
      </div>
      <Categories categories={categories} />
      <div className="category-list-container">
        {CategoryItemList.length > 0 &&
          CategoryItemList.map((list, index) => (
            <CotegoryItemList
              key={index}
              categoryTitle={list?.productCategoryName}
              itemList={list.products.data}
            />
          ))}
      </div>
    </div>
  );
};

export default CatalogHomeContent;
