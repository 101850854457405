import React, { ComponentState, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BusyIndicator } from "../HelperComps/BusyIndicator";
import {
  CategoryDetailModel,
  FiltersModel,
} from "../../services/Models/Categories.model";
import "./CategoryItemsList.css";
import { ItemCard } from "./Components/ItemCard";
import { CategoryService } from "../../services/API/category.service";
import { CommonUtils } from "../../services/Common/common.utils";
import { MessageAlert } from "../HelperComps/MessageAlert";
import Pagination from "../HelperComps/Pagination";

interface CategoryItemsListProps {}

const CategoryItemsList: React.FC<CategoryItemsListProps> = () => {
  const [state, setState] = useState<ComponentState>({
    isLoading: false,
  });
  const [dimensions, setDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [filters, setFilters] = useState<FiltersModel[]>([
    { value: "MattFinish", option: "Matt Finish" },
    { value: "MetalicFinish", option: "Metalic Finish" },
    { value: "GlassFinish", option: "Glass Finish" },
    { value: "GoldFinish", option: "Gold Finish" },
  ]);
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);
  const { productCategoryId } = useParams();
  const navigate = useNavigate();
  const categoryService = CategoryService.Instance;

  const defaultRowData = {} as CategoryDetailModel;
  const [rowData, setRowData] = useState<CategoryDetailModel>(defaultRowData);
  const currentPage = rowData?.products?.pageNumber ?? 0;
  const itemsPerPage = window.innerWidth < 1800 ? 12 : 15;
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems =
    rowData?.products?.data.length > itemsPerPage
      ? rowData?.products?.data.slice(indexOfFirstItem, indexOfLastItem)
      : rowData?.products?.data;

  useEffect(() => {
    handleLoadData();
  }, [productCategoryId]);

  const handleLoadData = async (pageNo?: number) => {
    setState({ isLoading: true });
    try {
      await categoryService
        .GetCategoryProducts({
          productCategoryId: productCategoryId as unknown as number,
          pageSize: itemsPerPage,
          pageNo: pageNo ?? 0,
        })
        .then((res) => {
          setRowData(res);
        });
    } catch (err) {
      setState({ messageAlert: CommonUtils.setErrorMessage(err) });
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleClick = (productId: number) => {
    navigate(`/category/item/${productId}`);
  };

  // const handleFilterChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedOption = event.target.value;
  //   addFilter(selectedOption);
  // };

  // const addFilter = (filterOption: string) => {
  //   const option = filters.find((f) => f.value === filterOption)?.option ?? "";
  //   if (!selectedFilters.includes(option)) {
  //     setSelectedFilters((prevFilters: string[]) => {
  //       return [...prevFilters, option!];
  //     });
  //   }
  // };

  // const removeFilter = (filterOption: string) => {
  //   const updatedFilters = selectedFilters.filter(
  //     (filter) => filter !== filterOption
  //   );
  //   setSelectedFilters(updatedFilters);
  // };

  const handleResize = () => {
    setDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize, false);
  }, []);

  // Check if rowData is empty or null before rendering
  if ((!rowData || Object.keys(rowData).length === 0) && !state.isLoading) {
    return (
      <div className="category-item-list-root">
        <BusyIndicator loading={state.isLoading} />
        <p>No data available.</p>
      </div>
    );
  }

  return (
    <div className="category-item-list-root">
      <BusyIndicator loading={state?.isLoading} />
      <MessageAlert message={state.messageAlert} />
      <div className="item-list-header-wraper">
        <div>
          <h2>{rowData?.productCategoryName}</h2>
          <p>{rowData?.description}</p>
        </div>
        <img
          src={process.env.REACT_APP_S3_BASE_URL + rowData?.imageUrl}
          alt=""
          className="skeleton-img-loader"
          loading={"lazy"}
        />
      </div>
      {/* <div className="category-list-filter">
        <div className="filter-wraper">
          <div className="select-filter-wraper">
            <select
              name="filter"
              id="filters"
              onChange={handleFilterChange}
              defaultValue={"default"}
            >
              <option value="default" disabled hidden>
                Stone Type
              </option>
              {filters.map((val, index) => (
                <option value={val.value} key={index}>
                  {val.option}
                </option>
              ))}
            </select>
          </div>
          <div className="filter-list-wraper">
            {selectedFilters.map((f, index) => (
              <div key={index} className="filter-list-item">
                <span>{f}</span>
                <button onClick={() => removeFilter(f)}>x</button>
              </div>
            ))}
          </div>
        </div>
        <input type="search" placeholder="Search..." />
      </div> */}
      <div className="category-list-items-wraper">
        {currentItems?.map((item, index) => (
          <ItemCard
            item={item}
            key={index}
            isTwoitemsPerRow={dimensions.width <= 480}
            handleClick={handleClick}
          />
        ))}
      </div>
      <Pagination
        rowData={rowData}
        setRowData={setRowData}
        helperFunction={handleLoadData}
      />
    </div>
  );
};

export default CategoryItemsList;
