import React, { ComponentState, FC, memo, useEffect, useState } from "react";
import "./ItemDetail.css";
import { useNavigate, useParams } from "react-router-dom";
import {
  ItemDetailModel,
  ItemDimensions,
  ItemModel,
  // ItemModel,
} from "../../services/Models/Categories.model";
import addIcon from "../../assets/Catalog Home/ItemDetail Add to Estimator icon.svg";
import UpRightArrow from "../../assets/HomePage/White Up Right Arrow.svg";
import CotegoryItemList from "./Components/ItemList";
import { ContactDetails } from "../Profile/Estimates/ContactDetails";
import { useAppDispacth, useAppSelector } from "../../store/Store";
import { addToCart, removeFromCart } from "../../store/features/CartSlice";
import { CategoryService } from "../../services/API/category.service";
import { CommonService } from "../../services/Common/common.service";
import { BusyIndicator } from "../HelperComps/BusyIndicator";
import { MessageAlert } from "../HelperComps/MessageAlert";
import { EstimatorModel } from "../../services/Models/Estimator.models";
import AuthService from "../../auth/auth.service";
import { toast } from "react-toastify";

interface ItemsDetailProps {}

const ItemDetail: FC<ItemsDetailProps> = memo((props) => {
  const [state = { isLoading: false }, setState] = useState<ComponentState>();
  const { productId } = useParams();
  const dispatch = useAppDispacth();
  const cartItems = useAppSelector((state) => state.cart.items);
  const categoryService = CategoryService.Instance;
  const currentUser = AuthService.getCurrentUser();
  const navigate = useNavigate();

  const defaultRowData = {} as ItemDetailModel;
  const [rowData, setRowData] = useState<ItemDetailModel>(defaultRowData);
  const [selectedImg, setSelectedImg] = useState<string>("");
  const [selectedDimensions, setSelectedDimensions] =
    useState<ItemDimensions>();
  const [selectedThickness, setSelectedThickness] = useState<{
    size: number;
    unit: string;
  }>();
  // rowData?.thickness[0].size
  const [selectedFinish, setSelectedFinish] = useState<string>();
  // rowData?.finish[0]
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [FeaturedItemList, setFeaturedItemList] = useState<{
    data: ItemModel[];
    pageNumber: number;
    pageSize: number;
    total: number;
  }>();
  const [estimateRequest, setEstimateRequest] = useState<EstimatorModel>(
    {} as EstimatorModel
  );

  useEffect(() => {
    const fetchCategories = async () => {
      setState({ isLoading: true });
      Promise.all([
        categoryService.getProductDetail(productId ?? ""),
        categoryService.GetFeaturedProducts(),
      ])
        .then((res) => {
          setRowData(res[0]);
          setSelectedImg(res[0].imageUrl[0]);
          setSelectedDimensions(res[0].dimension[0]);
          setSelectedThickness(res[0].thickness[0]);
          setSelectedFinish(res[0].finish[0]);
          setFeaturedItemList(res[1]);
          //console.log(res[1]);

          setState({ isLoading: false });
        })
        .catch((err) => {
          setState({
            isloading: false,
            messageAlert: CommonService.readErrorMessage(err),
          });
        });
    };
    fetchCategories();
  }, [productId]);

  const handleAddToCart = () => {
    const itemInCart = cartItems?.find(
      (item) => item.productId === rowData.productId
    );

    if (itemInCart) {
      // Item is in the cart, so remove it
      dispatch(removeFromCart({ productId: itemInCart.productId }));
    } else {
      // Item is not in the cart, so add it
      dispatch(
        addToCart({
          item: {
            productId: rowData.productId,
            productName: rowData.productName,
            skuCode: rowData.skuCode,
            imageUrl: rowData.imageUrl,
            dimension: selectedDimensions ?? rowData.dimension[0],
            thickness: selectedThickness ?? rowData.thickness[0],
            finish: selectedFinish ?? rowData?.finish[0],
          },
        })
      );
    }
  };

  const handleOpenDialog = () => {
    if (!(currentUser?.["MAT-TOKEN"] || currentUser?.email)) {
      toast.error("You need to login first");
      navigate("/auth/login");
      return;
    } else {
      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <div className="item-detail-root">
      <BusyIndicator loading={state?.isLoading} />
      <MessageAlert message={state.messageAlert} />
      <section className="item-detail-wraper">
        <div className="item-detail-left">
          <p className="item-category-mobile">
            <span>{rowData?.productCategoryName} </span>
            {rowData?.subCategory && (
              <span>&nbsp; &gt; {rowData?.subCategory}</span>
            )}
          </p>
          <img
            className="skeleton-img-loader"
            src={process.env.REACT_APP_S3_BASE_URL + selectedImg}
            alt=""
            loading={"lazy"}
          />
          <div className="preview-imgs">
            {rowData?.imageUrl?.map((img, index) => (
              <img
                className="skeleton-img-loader"
                src={process.env.REACT_APP_S3_BASE_URL + img}
                alt=""
                key={index}
                onClick={() => setSelectedImg(img)}
                loading={"lazy"}
              />
            ))}
          </div>
        </div>
        <div className="item-detail-right">
          <p className="item-category">
            <span>{rowData?.productCategoryName} </span>
            {rowData?.subCategory && (
              <span>&nbsp; &gt; {rowData?.subCategory}</span>
            )}
          </p>
          <h1>{rowData?.productName}</h1>
          <h3 style={{ marginBottom: 12 }}>{rowData?.skuCode}</h3>
          {/* <h5>Description</h5>
          <p>{rowData.productDescription}</p> */}
          <ul>
            {/* <li className="item-types">
              <span>Color Customization: </span>
              {rowData?.color?.join(", ")}
            </li> */}
            <li className="item-types">
              <span>Application : </span>
              {rowData?.application?.join(", ")}
            </li>
            <li className="item-types">
              <span>Prefabrication : </span>
              Available
              {/* {rowData?.Prefabrication ? "Available" : "Not Available"} */}
            </li>
          </ul>
          <h5>Dimensions</h5>
          <div className="item-dimensions-wraper">
            {rowData?.dimension?.map((size, index) => (
              <div
                key={index}
                className={`item-dimensions ${
                  selectedDimensions?.length === size.length &&
                  selectedDimensions?.width === size.width
                    ? "active"
                    : ""
                }`}
                onClick={() => setSelectedDimensions(size)}
              >
                <span>{size.measurementName.split("")[0]}</span>
                <span>
                  {size.length}
                  {size.unit} X {size.width}
                  {size.unit}
                </span>
              </div>
            ))}
          </div>
          <h5>Thickness</h5>
          <div className="item-dimensions-wraper">
            {rowData?.thickness?.map((thickness, index) => (
              <div
                className={`item-dimensions ${
                  selectedThickness?.size === thickness.size ? "active" : ""
                }`}
                key={index}
                onClick={() => setSelectedThickness(thickness)}
              >
                <span>{thickness.size}</span>
                <span>{thickness.unit}</span>
              </div>
            ))}
          </div>
          <h5>Finish</h5>
          <div className="item-dimensions-wraper">
            {rowData?.finish?.map((fnish, index) => (
              <div
                className={`item-dimensions ${
                  selectedFinish === fnish ? "active" : ""
                }`}
                key={index}
                onClick={() => setSelectedFinish(fnish)}
              >
                <span>{fnish.split("")[0]}</span>
                <span>{fnish}</span>
              </div>
            ))}
          </div>
          <div className="btns-wraper">
            <button onClick={() => handleAddToCart()}>
              {cartItems?.find((f) => f.productId === rowData?.productId) ? (
                "Remove from Estimator"
              ) : (
                <>
                  Add to Estimator <img src={addIcon} alt="" />
                </>
              )}
            </button>
            <button onClick={() => handleOpenDialog()}>
              Get Estimate Now <img src={UpRightArrow} alt="" />
            </button>
          </div>
        </div>
        <ContactDetails
          data={estimateRequest}
          setData={setEstimateRequest}
          open={openDialog}
          handleClose={handleCloseDialog}
          // handleSubmit={handleSubmit}
        />
      </section>
      <section className="featured-content">
        {/* {CategoryItemList?.data?.map((list, index) => ( */}
        <CotegoryItemList
          // key={index}
          categoryTitle={"Featured Content"}
          itemList={FeaturedItemList?.data ?? []}
        />
        {/* ))} */}
      </section>
    </div>
  );
});

export default ItemDetail;
