import React, { FC } from "react";
import "./Comps.css";
import { OnboardingModel } from "../auth.models";

interface Comp3Props {
  rowData: OnboardingModel;
  setRowData: React.Dispatch<React.SetStateAction<OnboardingModel>>;
}

const Comp3: FC<Comp3Props> = (props) => {
  return (
    <div className="comp-wraper">
      <h1>Where Do You Work ?</h1>
      <div className="work-wraper">
        <div>
          <label htmlFor="company">Company </label>
          <input
            type="text"
            id="company"
            value={props.rowData.companyName ?? ""}
            onChange={(e) => {
              props.setRowData({
                ...props.rowData,
                companyName: e.target.value,
              });
            }}
            placeholder="Company Name"
            required
          />
        </div>
        <div>
          <label htmlFor="company">Role </label>
          <input
            type="text"
            id="company"
            value={props.rowData.role ?? ""}
            onChange={(e) => {
              props.setRowData({
                ...props.rowData,
                role: e.target.value,
              });
            }}
            placeholder="Contactor"
          />
        </div>
        {/* <div>
          <label htmlFor="role">Role</label>
          <div className="select-role-wraper">
            <select
              name="role"
              id="role"
              defaultValue={"default"}
              value={props.rowData.role}
              onChange={(e) => {
                props.setRowData({
                  ...props.rowData,
                  role: e.target.value,
                });
              }}
            >
              <option value="default" disabled hidden>
                Select Role
              </option>
              {options.map((val, index) => (
                <option value={val} key={index}>
                  {val}
                </option>
              ))}
            </select>
          </div>
        </div> */}
      </div>
    </div>
  );
};
export default Comp3;
