import { OnboardingModel } from "../../auth/auth.models";
import { APIConfirmMessage } from "../Common/api-response.model";
import { HTTP_METHODS } from "../Common/common.constants";
import { CommonService } from "../Common/common.service";

export class ProfileService {
  private _baseUrl = process.env.REACT_APP_BASE_API_URL;
  private serviceUrl = `${this._baseUrl}user`;
  private static _instance: ProfileService;

  public static get Instance(): ProfileService {
    return this._instance || (this._instance = new this());
  }

  async getUserDetail(email: string): Promise<OnboardingModel> {
    let result!: OnboardingModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(`${this.serviceUrl}/userDetail?email=${email}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: OnboardingModel) => {
        result = response;
      });
    return result;
  }

  async updateUserDetail(payload: OnboardingModel): Promise<APIConfirmMessage> {
    let result!: APIConfirmMessage;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this.serviceUrl}/update/UserDetail`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }
}
