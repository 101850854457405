import React, { FC } from "react";
import "./Comps.css";
import { OnboardingModel } from "../auth.models";

interface Comp1Props {
  name: string;
  helperFunction: any;
}

const Comp1: FC<Comp1Props> = (props) => {
  return (
    <div className="comp-wraper">
      <h1>What is your name ?</h1>
      <input
        type="text"
        id="name"
        value={props.name ?? ""}
        onChange={(e) => props.helperFunction(e.target.value)}
        placeholder="Enter your full name"
        className="name-input"
        required
      />
    </div>
  );
};
export default Comp1;
