import React, { ComponentState, FC, memo, useState } from "react";
import "./ItemCard.css";
import { ItemModel } from "../../../services/Models/Categories.model";
import HoverIcon from "../../../assets/Catalog Home/Hover icon1.svg";
import DeleteIcon from "../../../assets/Estimator/Estimator Item Detete Icon.svg";

type ItemCardProps = {
  item: ItemModel;
  handleClick: (productId: number) => void;
  imgHeight?: string;
  isEstimatorPage?: boolean;
  helperFuntion?: any;
  isTwoitemsPerRow?: boolean;
};

export const ItemCard: FC<ItemCardProps> = memo((props) => {
  return (
    <div
      className="list-item"
      style={{
        minWidth: !props.isTwoitemsPerRow ? "250px" : "140px",
        height: !props.isTwoitemsPerRow ? "100%" : "90%",
      }}
      onClick={() => {
        props.handleClick(props.item.productId);
      }}
    >
      <div
        className="list-item-image"
        style={{ height: props.imgHeight ?? "" }}
      >
        <img
          loading={"lazy"}
          className="cover-img skeleton-img-loader"
          src={process.env.REACT_APP_S3_BASE_URL + props.item?.imageUrl[0]}
          alt={process.env.REACT_APP_S3_BASE_URL + props.item?.imageUrl[0]}
        />
        <img
          loading={"lazy"}
          className="hover-img skeleton-img-loader"
          src={
            !props.isEstimatorPage
              ? process.env.REACT_APP_S3_BASE_URL +
                (props.item?.imageUrl[1] ?? props.item?.imageUrl[0])
              : process.env.REACT_APP_S3_BASE_URL + props.item?.imageUrl[0]
          }
          alt={
            !props.isEstimatorPage
              ? process.env.REACT_APP_S3_BASE_URL + props.item?.imageUrl[1]
              : process.env.REACT_APP_S3_BASE_URL + props.item?.imageUrl[0]
          }
        />
        <button
          className="hover-icon"
          type="button"
          onClick={(e) => {
            e.stopPropagation();
            if (props.isEstimatorPage) {
              props.helperFuntion();
            } else {
              props.handleClick(props.item.productId);
            }
          }}
        >
          <img
            src={!props.isEstimatorPage ? HoverIcon : DeleteIcon}
            alt="icon"
          />
        </button>
      </div>
      <div
        className="list-item-text"
        style={{ padding: props.isTwoitemsPerRow ? "0px" : "10px" }}
      >
        <h3 style={{ fontSize: props.isTwoitemsPerRow ? "16px" : "24px" }}>
          {props.item.productName} <span>➔</span>
        </h3>
        <p style={{ fontSize: props.isTwoitemsPerRow ? "12px" : "16px" }}>
          {props.item.skuCode}
        </p>
      </div>
      {props.isEstimatorPage && (
        <button
          className="remove-item-btn"
          onClick={(e) => {
            e.stopPropagation();
            if (props.isEstimatorPage) {
              props.helperFuntion();
            }
          }}
        >
          —
        </button>
      )}
    </div>
  );
});
