import React, { FC, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Logo from "../assets/Footer/Matbook Logo.svg";
import Eye from "../assets/Auth/eye.svg";
import GoogleIcon from "../assets/Auth/Google_logo.svg";
import { BusyIndicator } from "../sections/HelperComps/BusyIndicator";
import { PopupDialog } from "../sections/HelperComps/PopupDialog";
import { GoogleOauthResponseModel, UserLoginModel } from "./auth.models";
import { toast } from "react-toastify";
import AuthService from "./auth.service";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { useDispatch } from "react-redux";
import { holdPassword } from "../store/features/OnboardingSlice";

interface LoginPageProps {}

const LoginPage: FC<LoginPageProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowData, setRowData] = useState<UserLoginModel>({} as UserLoginModel);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isForgot, setIsForgot] = useState<boolean>(false);
  const [otp, setOtp] = useState<string[]>(["", "", "", ""]);

  const [isChangedPassword, setIsChangedPassword] = useState<boolean>(false);
  const [changePassword, setChangePassword] = useState<{
    password: string;
    confirmPassword: string;
  }>({ password: "", confirmPassword: "" });
  const [showChangePassword1, setShowChangePassword1] =
    useState<boolean>(false);
  const [showChangePassword2, setShowChangePassword2] =
    useState<boolean>(false);

  const otpInputsRefs = [
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
    useRef<HTMLInputElement>(null),
  ];

  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = AuthService.getCurrentUser();
  const authService = AuthService.Instance;

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    if (!isValidEmail(rowData.userName)) {
      setIsLoading(false);
      toast.warning("Please enter a valid email");
      return false;
    } else {
      await authService //send the payload to the backend
        .login(rowData)
        .then((res) => {
          setIsLoading(false);
          // if (res.redirectUrl.includes("auth")) {
          //   dispatch(holdPassword({ tempPassword: rowData.password }));
          // }
          navigate(res.redirectUrl);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    }
  };

  const isValidEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  // const isValidPhoneNumber = (value: string): boolean => {
  //   const phoneRegex = /^\d{10}$/;
  //   return phoneRegex.test(value);
  // };

  const handleGoogleLogin = async (
    credentialResponse: GoogleOauthResponseModel
  ) => {
    setIsLoading(true);
    await authService
      .googleOathResponse(credentialResponse.credential ?? "")
      .then((res) => {
        setIsLoading(false);
        navigate(res.redirectUrl);
        // console.log("#", res.redirectionUrl);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  // const handleAppleIDLogin = () => {
  // Handle Apple ID login logic here
  // };

  const handleGenerateOtp = (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    // Check if there is a valid email or phone number
    if (!isValidEmail(rowData.userName)) {
      setIsLoading(false);
      toast.warning("Please enter a valid email");
    } else {
      //   navigate(`/auth/forgot/${email}`);
      authService
        .generateOtp(rowData.userName)
        .then((res) => {
          setIsLoading(false);
          setIsForgot(true);
          toast.success(res.message);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    }
  };

  const handleInputChange = (index: number, value: string) => {
    if (index === 3 && value.length > 1) return;
    const newOtp = [...otp];
    newOtp[index] = value;

    setOtp(newOtp);

    // Move to the next input
    if (value !== "" && index < 3) {
      otpInputsRefs[index + 1].current?.focus();
    }
  };

  const handleKeyDown = (
    index: number,
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    // Move to the previous input field on backspace
    if (event.key === "Backspace" && index > 0 && otp[index] === "") {
      otpInputsRefs[index - 1].current?.focus();
    }
  };

  const handleSubmitOtp = async (e: React.FormEvent) => {
    e.preventDefault();
    const enteredOtp = otp.join("");

    setIsLoading(true);
    if (enteredOtp.length === 4) {
      await authService
        .submitOtp(rowData.userName, enteredOtp)
        .then((res) => {
          setIsLoading(false);
          toast.success("OTP submitted successfully, now change your password");
          setIsChangedPassword(true);
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err.message);
        });
    } else {
      toast.warning("Plaese enter 4 digits!");
    }
    setIsLoading(false);
  };

  const handleChangepassword = async (e: React.FormEvent) => {
    e.preventDefault();
    if (changePassword.password !== changePassword.confirmPassword) {
      // console.log(changePassword.password, changePassword.confirmPassword);
      toast.warning("Passwords do not match");
      return false;
    }
    setIsLoading(true);
    await authService
      .updatePassword(rowData.userName, changePassword.password)
      .then((res) => {
        setIsLoading(false);
        toast.success("Password updated successfully. Please login again!");
        setIsForgot(false);
        setIsChangedPassword(false);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  if (currentUser?.["MAT-TOKEN"]) {
    toast.warning("Please logout");
    setTimeout(() => {
      navigate("/profile/dashboard");
    });
  }

  return (
    <div className="login-root-container">
      <BusyIndicator loading={isLoading} />
      <div className="login-container">
        <img
          src={Logo}
          alt="Logo"
          className="logo"
          onClick={() => navigate("/")}
        />
        <h2>
          Start Your Journey With <span>Matbook</span>
        </h2>
        <p>Your Global Construction Material Supplier</p>
        <form onSubmit={handleSubmit} className="login-form">
          <div className="form-row">
            <label>Email</label>
            <input
              type="text"
              placeholder="Enter your email address"
              value={rowData.userName}
              onChange={(e) =>
                setRowData({ ...rowData, userName: e.target.value })
              }
              required
            />
          </div>
          <div className="form-row">
            <label>Password</label>
            <input
              type={showPassword ? "text" : "password"}
              placeholder="Enter your password"
              value={rowData.password}
              onChange={(e) =>
                setRowData({ ...rowData, password: e.target.value })
              }
              required
            />
            <img
              src={Eye}
              alt=""
              onClick={() => setShowPassword((hide) => !hide)}
            />
          </div>
          <button
            type="button"
            className="forgot-btn"
            onClick={handleGenerateOtp}
          >
            forgot password?
          </button>
          <button type="submit" className="submit-btn">
            {isLoading ? "Loading..." : "Login"}
          </button>
        </form>

        <div className="social-login">
          <GoogleOAuthProvider
            clientId={process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID ?? ""}
          >
            <GoogleLogin
              onSuccess={(credentialResponse) =>
                handleGoogleLogin(credentialResponse)
              }
              onError={() => {
                setIsLoading(false);
                toast.error("Some error occurred. Please try after sometime");
              }}
              containerProps={{
                style: {
                  width: 300,
                  maxWidth: "100%",
                  height: "100%",
                  alignSelf: "center",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                },
              }}
              theme="outline"
              type="standard"
              width={"100%"}
              size="large"
              useOneTap={!isForgot}
              ux_mode="popup"
              text="signin_with"
              context="signin"
            />
          </GoogleOAuthProvider>
          {/* <button onClick={handleGoogleLogin}>
            <img src={GoogleIcon} alt="" />
            Login with Google
          </button> */}
        </div>
        <p>
          New To Matbook?{" "}
          <Link to="/auth/signup" style={{ textDecoration: "none" }}>
            <span style={{ color: "#32919D" }}>Sign up</span>
          </Link>
        </p>
      </div>

      <PopupDialog
        open={isForgot}
        dialogTile="Forgot Your Password?"
        handleClose={() => {
          setIsForgot(false);
          setOtp(["", "", "", ""]);
          setIsChangedPassword(false);
        }}
        hideHeaderFooter={true}
      >
        <div className="login-container forgot-form">
          <h2>
            {!isChangedPassword ? "Forgot Your Password?" : "Change Password"}
          </h2>
          {!isChangedPassword ? (
            <p>
              Don’t worry! We have sent a 4-digit verification code to Your{" "}
              {isValidEmail(rowData.userName) ? "email" : "phone"}{" "}
              <span style={{ color: "#CF6837" }}>{rowData.userName}</span>
            </p>
          ) : (
            ""
          )}
          <form
            onSubmit={(e) => {
              if (isChangedPassword) handleChangepassword(e);
              else handleSubmitOtp(e);
            }}
            className="login-form forgot-form"
          >
            {!isChangedPassword ? (
              <div className="form-otp-row">
                {otp.map((digit, index) => (
                  <input
                    key={index}
                    ref={otpInputsRefs[index]}
                    type="number"
                    value={digit}
                    maxLength={1}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) =>
                      handleKeyDown(index, e)
                    }
                  />
                ))}
              </div>
            ) : (
              <div style={{ width: "100%" }}>
                <div className="form-row">
                  <label>New password</label>
                  <input
                    type={showChangePassword1 ? "text" : "password"}
                    placeholder="Enter your password"
                    value={changePassword.password}
                    onChange={(e) =>
                      setChangePassword({
                        ...changePassword,
                        password: e.target.value,
                      })
                    }
                    required
                  />
                  <img
                    src={Eye}
                    alt=""
                    onClick={() => setShowChangePassword1((hide) => !hide)}
                  />
                </div>
                <div className="form-row">
                  <label>Confirm new password</label>
                  <input
                    type={showChangePassword2 ? "text" : "password"}
                    placeholder="Enter your password again"
                    value={changePassword.confirmPassword}
                    onChange={(e) =>
                      setChangePassword({
                        ...changePassword,
                        confirmPassword: e.target.value,
                      })
                    }
                    required
                  />
                  <img
                    src={Eye}
                    alt=""
                    onClick={() => setShowChangePassword2((hide) => !hide)}
                  />
                </div>
              </div>
            )}
            <button
              type="submit"
              className="btn btn-primary"
              disabled={isLoading || otp.join("").length < 4}
            >
              {isLoading ? "Loading..." : "Confirm"}
            </button>
          </form>
        </div>
        {/* </div> */}
      </PopupDialog>
    </div>
  );
};

export default LoginPage;
