import { APIConfirmMessage } from "../Common/api-response.model";
import { HTTP_METHODS } from "../Common/common.constants";
import { CommonService } from "../Common/common.service";
import {
  EstimatesItemDetailModel,
  EstimatesListModel,
  EstimatorModel,
} from "../Models/Estimator.models";

export class EstimateService {
  private _baseUrl = process.env.REACT_APP_BASE_API_URL;
  private serviceUrl = `${this._baseUrl}estimate/`;
  private static _instance: EstimateService;

  public static get Instance(): EstimateService {
    return this._instance || (this._instance = new this());
  }

  async createEstimate(payload: EstimatorModel): Promise<APIConfirmMessage> {
    let result!: APIConfirmMessage;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.POST, payload);
    await fetch(`${this.serviceUrl}createEstimate`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: any) => {
        result = response;
      });
    return result;
  }

  async getEstimates(email: string): Promise<EstimatesListModel> {
    let result!: EstimatesListModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(`${this.serviceUrl}userListing/${email}`, requestInfo)
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: EstimatesListModel) => {
        result = response;
      });
    return result;
  }

  async getEstimateDetail(
    email: string,
    estimationId: string
  ): Promise<EstimatesItemDetailModel> {
    let result!: EstimatesItemDetailModel;
    const requestInfo = CommonService.RequestInfo(HTTP_METHODS.GET);
    await fetch(
      `${this.serviceUrl}estimationDetail/${email}/${estimationId}`,
      requestInfo
    )
      .then((response: Response) => {
        return CommonService.HttpResponse(response);
      })
      .then((response: EstimatesItemDetailModel) => {
        result = response;
      });
    return result;
  }
}
