import React, { FC, useLayoutEffect, useState } from "react";
import "./InnerCards.css";

interface InnerCardsProps {
  title: string;
  active: boolean;
  icon: string;
  onClick: () => void;
  subTitle: string;
}

const InnerCards: FC<InnerCardsProps> = ({
  title,
  active,
  icon,
  onClick,
  subTitle,
}) => {
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);

  useLayoutEffect(() => {
    const updateWindowDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    updateWindowDimensions();

    window.addEventListener("resize", updateWindowDimensions);
    return () => window.removeEventListener("resize", updateWindowDimensions);
  }, []); // Empty dependency array ensures this effect runs once on mount

  return (
    <div className="inner-card-wrapper" onClick={onClick}>
      {icon && (
        <div className="inner-card-icon">
          <img
            height={width > 480 ? 60 : 50}
            width={width > 480 ? 100 : 50}
            src={icon}
            alt={title}
          ></img>
        </div>
      )}
      <div
        className="inner-card-root"
        style={{
          backgroundColor: active ? "#CF6837" : "#F4E6D0",
          color: active ? "#F9F9F9" : "#CF6837",
          height:
            active && width < 480
              ? "219px"
              : width > 480 || !active
              ? "100px"
              : "fit-content",
        }}
      >
        <div className={`inner-card-text ${active ? "active" : ""}`}>
          {title}
        </div>
        <div className={`inner-card-subtext ${active ? "active" : ""}`}>
          {subTitle}
        </div>
      </div>
    </div>
  );
};

export default InnerCards;
