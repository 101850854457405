import React, { FC, useEffect, useState } from "react";
import "./Onboarding.css";
import BackIcon from "../assets/Auth/Back_icon.svg";
import NextIcon from "../assets/Auth/Next_icon.svg";
import Comp1 from "./OnboardingComps/Comp1";
import Comp2 from "./OnboardingComps/Comp2";
import Comp3 from "./OnboardingComps/Comp3";
import { OnboardingModel } from "./auth.models";
import { useNavigate } from "react-router-dom";
import { BusyIndicator } from "../sections/HelperComps/BusyIndicator";
import { toast } from "react-toastify";
import AuthService from "./auth.service";

type OnboardingProps = {};

const Onboarding: FC<OnboardingProps> = (props) => {
  const email = AuthService.getCurrentUser()?.email;
  // const tempPassword = useAppSelector((state) => state.onboarsing.tempPassword);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [index, setIndex] = useState<number>(1);
  const [rowData, setRowData] = useState<OnboardingModel>({
    email: email ?? "",
  } as OnboardingModel);
  const [isAnimate, setIsAnimate] = useState<boolean>(false);

  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const authService = AuthService.Instance;
  const NextBtnText: string[] = ["Next", "Almost There", "Finish"];

  const handleNext = () => {
    // setState({ isLoading: true });
    if (!validateData()) return;

    if (index < 3) setIndex(index + 1);
    else if (index === 3) handleSubmit();
  };

  const handleBack = () => {
    if (index > 1) setIndex(index - 1);
  };

  const handleNameChange = (name: string) => {
    setRowData({ ...rowData, name: name });
  };

  const handleSubmit = async () => {
    if (!validateData()) return;
    setIsLoading(true);

    await authService
      .userOnboarding(rowData)
      .then((res) => {
        setIsLoading(false);
        // dispatch(clearPassword());
        setIndex(4);

        setTimeout(() => {
          setIsAnimate(true);
        }, 100);

        setTimeout(() => {
          navigate("/catalog");
        }, 1500);
      })
      .catch((err) => {
        setIsLoading(false);
        toast.error(err.message);
      });
  };

  const isValidEmail = (value: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value);
  };

  const isValidPhoneNumber = (value: string): boolean => {
    const phoneRegex = /^\d{10}$/;
    return phoneRegex.test(value);
  };

  const validateData: () => boolean = () => {
    // validate the data before sending

    setIsLoading(true);
    if (index === 1 && !rowData.name) {
      setIsLoading(false);
      toast.warning("Please enter Your Name");
      return false;
    }

    if (index === 2) {
      if (!isValidEmail(rowData.email)) {
        setIsLoading(false);
        toast.warning("Please enter Your email ID");
        return false;
      }

      if (!isValidPhoneNumber(rowData.phoneNumber?.toString())) {
        setIsLoading(false);
        toast.warning("Please enter Your Phone Number");
        return false;
      }
    }

    if (index === 3 && !rowData.companyName) {
      setIsLoading(false);
      toast.warning("Please enter Your Company Name");
      return false;
    }

    setIsLoading(false);
    return true;
  };

  if (!email) {
    toast.warning("Please sign up, to initialize onboarding");
    setTimeout(() => {
      navigate("/auth/signup");
    }, 0);
    return false;
  }

  return (
    <div
      className="onboarding-root"
      style={{ maxHeight: index > 3 ? "70vh" : "" }}
    >
      <BusyIndicator loading={isLoading} />
      <button
        onClick={handleBack}
        disabled={index > 3}
        style={{ opacity: index > 3 ? 0 : 1 }}
      >
        <img src={BackIcon} alt="" />
        Back
      </button>
      <div
        className={`onboarding-wraper ${isAnimate ? "animate" : ""}`}
        style={{ height: index === 4 ? 100 : "" }}
      >
        <div className="common-text">
          <h1>
            Welcome To <span style={{ color: "#CF6837" }}>Matbook!</span>
          </h1>
          <p style={{ display: index === 4 ? "none" : "" }}>
            Thank you for choosing us as your one-stop solution for all your
            construction material procurements. With our network spanning the
            entire world, you are guaranteed the best prices for the highest
            quality construction materials.
          </p>
        </div>
        {index === 1 && (
          <Comp1 name={rowData.name} helperFunction={handleNameChange} />
        )}
        {index === 2 && <Comp2 rowData={rowData} setRowData={setRowData} />}
        {index === 3 && <Comp3 rowData={rowData} setRowData={setRowData} />}
      </div>
      <div
        className="onboard-footer"
        style={{ display: index === 4 ? "none" : "" }}
      >
        <span>{index}/3</span>
        <button onClick={handleNext} className={`nxt-btn${index}`}>
          {index > 0 && index < 4 && NextBtnText[index - 1]}
          <img src={NextIcon} alt="" />
        </button>
      </div>
    </div>
  );
};

export default Onboarding;
